import { ActionTypes } from "../constants/action-types";
const initialState = {
};


export function treeDataReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.SET_TREE_DATA:
            return { ...state, treeDataDetails: payload };
        default:
            return state;
    }
}