import { Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { AlertController, MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor, Plugins } from "@capacitor/core";
import { ActivatedRoute, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { SignupPage } from './pages/signup/signup.page';
import { PricingPage } from './pages/pricing/pricing.page';
import { AutocloseOverlaysService } from './services/overlay/autoclose-overlays.service';
import { ApiService } from './services/api/api.service';
import { Events } from './services/events';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard } from '@capacitor/keyboard';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Store } from '@ngrx/store';
import { setActiveLocations, setActiveLocationsLastupdate, setAddressType, setAllUserLocation, setAlredyHaveLocation, setLastContactFetch, setLocation, setLocationID, setLocationJobsiteContacts, setLocationaImportantDates, setPreviouslyUsedContacts, setSelectedLocation, setUserLocalDataUpdate } from 'src/store/actions/location';
import { setAuthentication } from 'src/store/actions/authenticate';
import { setNavigationParameter } from 'src/store/actions/navigationVariables';
import { setUser } from 'src/store/actions/user';
import { AuthenticationService } from './services/auth/authentication.service';
import { setIsChangeTrade, setSelectedTrade, setTradeData } from 'src/store/actions/trades';
import { setExpandsPlans, setPunchItem, setPunchItemLocation, setPunchItemsStore, setSelectedLocationPunchItems } from 'src/store/actions/punchItemDetail';
import { setActivityFilters, setJobsiteFilters } from 'src/store/actions/filters';
import { setIsChangeRoom, setRoomData } from 'src/store/actions/room';
import { AddPunchitemSameLocationPage } from './modals/add-punchitem-same-location/add-punchitem-same-location.page';
import { CommonService } from 'src/app/services/common/common.service';
import { ConstantService } from './services/constant/constant.service';
import { setDescriptionData } from 'src/store/actions/description';
import { setDevelopmentData } from 'src/store/actions/development';
import { setTreeDetail } from 'src/store/actions/treeData';
import { setPlanDetail } from 'src/store/actions/planDetails';
import { setAlertsActiveLocationsLastupdate, setAlertsLocations, setScheduledLocations } from 'src/store/actions/alerts';
import { App, URLOpenListenerEvent } from '@capacitor/app';

let html = document.getElementsByTagName("ion-backdrop")[0];
console.log(document.getElementsByTagName("ion-backdrop"));

if (Capacitor.platform !== "web") {
  Keyboard.addListener('keyboardWillShow', (info: any) => {
    if (document.getElementsByTagName("ion-backdrop")[0]) {
      document.getElementsByTagName("ion-backdrop")[0].style.height = "130%";
    }
  });

  Keyboard.addListener('keyboardWillHide', () => {
    if (document.getElementsByTagName("ion-backdrop")[0]) {
      document.getElementsByTagName("ion-backdrop")[0].style.height = 'auto';
    }
  });
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public isLogin = false;
  public selectedIndex = 0;
  titls: any;
  public appPages = [
    {
      title: 'Home',
      url: '/home'
    },
    {
      title: 'FAQ',
      url: '/faq'
    },
    {
      title: 'How does it work?',
      url: '/how-does-it-work'
    }
  ];
  public appPagesLogin = [
    {
      title: 'Jobsites',
      url: '/tabs/jobsites',
      image: 'assets/icon/menu/home_color.svg',
      image_white: 'assets/icon/menu/home_white.svg'
    },
    {
      // title: 'Activity',
      title: 'Reminders',
      url: '/tabs/activity',
      icon: 'hammer',
      image: 'assets/icon/menu/activity.svg',
      image_white: 'assets/icon/menu/a.svg'
    },
    {
      title: 'Finished punch lists',
      url: '/tabs/jobsites',
      image: 'assets/icon/menu/finished.svg',
      image_white: 'assets/icon/menu/b.svg'
    },
    {
      title: 'Punch list drafts',
      url: '/tabs/punchlist-draft',
      image: 'assets/icon/menu/draft.svg',
      image_white: 'assets/icon/menu/c.svg'
    },
    {
      title: 'FAQ',
      url: '/faq',
      image: 'assets/icon/menu/faq.svg',
      image_white: 'assets/icon/menu/e.svg'
    },
    {
      title: 'Contacts',
      url: '/tabs/jobsites',
      image: 'assets/icon/menu/contact.svg',
      image_white: 'assets/icon/menu/f.svg'
    },
  ];
  auth: any;
  userData: any;
  punchItemData:any;
  locationData:any;
  lastContactFetch;

  isRedAlerts = false;
  isOrangeAlerts = false;
  allLocalLocationsDummy: any;


  constructor(
    private platform: Platform,
    private router: Router,
    private menuCtrl: MenuController,
    private modalController: ModalController,
    private navCtrl: NavController,
    private autocloseOverlaysService: AutocloseOverlaysService,
    public apiService: ApiService,
    private events: Events,
    private detectChange: ChangeDetectorRef,
    private authServices: AuthenticationService,
    private route: ActivatedRoute,
    private screenOrientation: ScreenOrientation,
    private store: Store<any>,
    private common: CommonService,
    private zone: NgZone,
    private constant: ConstantService,
    private alertController: AlertController
  ) {
    this.initializeApp();

    this.events.subscribe('alerts', (data: any) => {
      this.store.select<any>('locationData').subscribe(state => this.allLocalLocationsDummy = state);
     

      this.allLocalLocationsDummy?.activeLocations.forEach(element => {
        // console.log(this.allLocalLocationsDummy,"login event call -----------------11111--------------------- alerts alerts alerts alerts alerts alerts alerts",element);
        if (element.hasOwnProperty('redAlerts') && element.redAlerts.length > 0) {
          this.isRedAlerts = true
        }
      
        if (element.hasOwnProperty('orangeAlerts') && element.orangeAlerts.length > 0) {
          this.isOrangeAlerts = true
        }        
        console.log(this.isOrangeAlerts,"fhdfgh dfgtherht dfghfrthyrfth dfgh",this.isRedAlerts);
        
      });

      // this.isRedAlerts = data.isRedAlerts
      // this.isOrangeAlerts = data.isOrangeAlerts
    });

  }

  ionViewDidEnter() {
    this.platform.ready().then(() => {
      if (Capacitor.platform !== "web") {
        Keyboard.setScroll({ isDisabled: false });
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.platform !== "web") {
        Keyboard.setScroll({ isDisabled: false })
        StatusBar.setStyle({ style: Style.Dark })
      }
      
      console.log("============== INITIALIZE APP ===========")


      this.store.select<any>('auth').subscribe(state => this.auth = state);
      this.store.select<any>('user').subscribe(state => this.userData = state);
      this.store.select<any>('locationData').subscribe(state => this.locationData = state);
      console.log(this.userData, "this.userDatathis.userDatathis.userData");
      
      console.log(Object.keys(this.userData).length === 0, this.userData?.user)
      // if (Object.keys(this.userData).length != 0 && this.userData?.user != null) {
      this.authServices.GetAuthenticationToken().then(async (tokenRes: any) => {
        console.log(tokenRes, "for token");
        // signIn
        if (tokenRes) {
          let accessToken = tokenRes.accessToken.jwtToken;
          let sessionToken = tokenRes.idToken.jwtToken;
          let refreshToken = tokenRes.refreshToken.token;

          // set userData and token into Redux
          let uData = {
            token: {
              accessToken: accessToken,
              sessionToken: sessionToken,
              refreshToken: refreshToken
            },
            cognitoUser: {
              attributes: {
                email: this.auth?.authenticationData?.cognitoUser?.attributes?.email,
                sub: this.auth?.authenticationData?.cognitoUser?.attributes?.sub
              }
            },
            login: true
          }
          await this.store.dispatch(setAuthentication(uData));
        }
      }, (err) => {
        console.log(err, "error");
      })

      if(this.userData?.user != null && this.userData?.user != undefined) {
        this.readDataFromUserLocalDataTable();
        this.getPreviousContact();
      }
      
      // }

      SplashScreen.hide();
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log(" appUrlOpen  appUrlOpen appUrlOpen appUrlOpen appUrlOpen",event);
      
			this.zone.run(() => {
				const domain = 'easypunchlist.com';

				const pathArray = event.url.split(domain);
				// The pathArray is now like ['https://devdactic.com', '/details/42']

				// Get the last element with pop()
				const appPath = pathArray.pop();
        console.log(" appPath  appPath appPath appPath appPath",appPath);
				if (appPath) {
					this.router.navigateByUrl(appPath);
				}
			});
		});
  }

  ngOnInit() {
    this.events.subscribe("profileClicked", (data: any) => {
      this.selectedIndex = 10;
    });

    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    this.router.events.subscribe((event: any): void => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.autocloseOverlaysService.trigger();
        }
      }
    });

    // Event For Menu  
    this.events.subscribe('loginn', (data: any) => {
      console.log("login event call")
      if (data.login == true) {
        console.log("login event call true")
        this.isLogin = true;
        this.detectChange.detectChanges();
      } else {
        console.log("login event call false")
        this.isLogin = false;
        this.detectChange.detectChanges();
      }
    });

    if (Capacitor.platform !== "web") {
      console.log(this.screenOrientation.type);
      if (this.platform.is('tablet')) {
        this.screenOrientation.unlock();
      } else {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY);
      }
    }
  }
  async goLogin() {
    this.menuCtrl.close();
    const modal = await this.modalController.create({
      component: LoginPage,
      cssClass: 'modal_login',
      backdropDismiss: false
    });
    return await modal.present();
  }

  async goSignup() {
    this.menuCtrl.close();
    const modal = await this.modalController.create({
      component: SignupPage,
      cssClass: 'modal_login',
      backdropDismiss: false
    });
    return await modal.present();
  }

  async pricing() {
    this.menuCtrl.close();
    const modal = await this.modalController.create({
      component: PricingPage,
      cssClass: 'modal_login',
      backdropDismiss: false
    });
    return await modal.present();
  }

  async logout() {
    this.menuCtrl.close();
    localStorage.clear();
    await this.store.dispatch(setLocation(null));
    await this.store.dispatch(setSelectedLocation(null));
    await this.store.dispatch(setLocationID(null));
    await this.store.dispatch(setAlredyHaveLocation(null));
    await this.store.dispatch(setAddressType(null));
    await this.store.dispatch(setAuthentication(null));
    await this.store.dispatch(setNavigationParameter(null));
    await this.store.dispatch(setUser(null));
    await this.store.dispatch(setTradeData(null));
    await this.store.dispatch(setIsChangeTrade(false));
    await this.store.dispatch(setSelectedTrade(null));
    await this.store.dispatch(setPunchItem(null));
    await this.store.dispatch(setPunchItemLocation(null));
    await this.store.dispatch(setSelectedLocationPunchItems(null));
    await this.store.dispatch(setActivityFilters(null));
    await this.store.dispatch(setJobsiteFilters(null));
    await this.store.dispatch(setRoomData(null));
    await this.store.dispatch(setIsChangeRoom(false));
    await this.store.dispatch(setActiveLocations(null));
    await this.store.dispatch(setActiveLocationsLastupdate(null));
    await this.store.dispatch(setAllUserLocation(null));
    await this.store.dispatch(setDescriptionData(null));
    await this.store.dispatch(setDevelopmentData(null));
    await this.store.dispatch(setTreeDetail(null));
    await this.store.dispatch(setPunchItemsStore(null))
    await this.store.dispatch(setUserLocalDataUpdate(null))
    await this.store.dispatch(setExpandsPlans(null))
    await this.store.dispatch(setPreviouslyUsedContacts(null))
    await this.store.dispatch(setLastContactFetch(null));
    await this.store.dispatch(setPlanDetail(null));
    await this.store.dispatch(setLocationJobsiteContacts(null));
    await this.store.dispatch(setLocationaImportantDates(null));
    await this.store.dispatch(setAlertsLocations(null));
    await this.store.dispatch(setScheduledLocations(null));
    await this.store.dispatch(setAlertsActiveLocationsLastupdate(null));
    
    this.events.publish('loginn', {
      login: false,
    });
    this.navCtrl.navigateRoot(['/home']);
  }

  openPages(p) {
    this.selectedIndex = 7;
    this.titls = p;
  }

  addSelectedIndex(i) {
    this.selectedIndex = i;
    this.titls = '';
  }

  async openMoreInfo() {
    const modal = await this.modalController.create({
      component: AddPunchitemSameLocationPage,
      cssClass: 'modal_all',
      backdropDismiss: false
    });

    modal.onDidDismiss().then(async (data) => {
      console.log(data,"daataatatta", this.locationData);

      let currentLocation = this.locationData

      if(data?.data == 'yes'){
        if(this.locationData?.primary_supervisor_user?.id != this.userData?.user?.id){
          if(this.locationData?.street_address != '' && this.locationData?.unit != ''){
            // For Both
            this.createLocation('both')
          }else if(this.locationData?.street_address != '' && this.locationData?.unit == ''){
            // Street Address
            this.createLocation('streetAddress')
          }else if(this.locationData?.unit != '' && this.locationData?.street_address == '' ){
            // manual
            this.createLocation('manual')
          }
        }else{
          this.allDispatchForLocation(currentLocation);
        }
      } else if (data?.data == 'no') {
        this.router.navigate(['/tabs/create-punchlist']);
      }
    });

    return await modal.present();
  }

  capitalizeName(name) {
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
  }

  async createLocation(forWhat) {
    let data = {};
    if(forWhat == 'manual'){
      data = {
        development_site: this.capitalizeName(this.locationData?.development_site),
        location_type: forWhat,
        primary_supervisor: this.userData?.user?.id,
        street_address: "",
        unit: this.locationData?.unit + "",
        status: "status#draft",
        GSI2PK : this.userData?.user?.id,
        GSI2SK : this.locationData?.unit + " " + this.capitalizeName(this.locationData?.development_site),
        primary_super_contact: this.userData?.user?.email,
        display_name: this.userData?.user?.fullname
      }

      let checkData = {
        id: this.userData?.user?.id,
        location: this.locationData?.unit + " " + this.capitalizeName(this.locationData?.development_site),
      }
      this.common.callApi(
        this.constant.LOCATION_CHECK,
        checkData,
        'POST',
        '',
        false,
        false,
        true
      ).subscribe(async (results: any) => {
        console.log("Check For location!", results);
        
        if(results?.length <= 0){
          this.common.callApi(
            this.constant.CREATE_LOCATION_V2,
            data,
            'POST',
            '',
            false,
            false,
            true
          ).subscribe(async (results: any) => {
            console.log("Created Successfully from app component!", results);
            this.allDispatchForCreatedLocation(results);
          }, (err) => {
            console.log(err);
          })
        }else{

          await this.store.dispatch(setLocationID(results?.location_check[0].id));
          await this.store.dispatch(setAlredyHaveLocation('yes'));

          let selected = {
            unumber: results?.location_check[0]?.unit,
            development: results?.location_check[0]?.development_site,
            address: results?.location_check[0]?.street_address,
            location_status: results?.location_check[0]?.location_status,
            primary_supervisor: results?.location_check[0]?.primary_supervisor
          }
          await this.store.dispatch(setSelectedLocation(selected));
          await this.store.dispatch(setAddressType(results?.location_check[0]?.location_type));
          let navigationExtras: NavigationExtras = {
            queryParams: {
              fromWhere: "createPunchList",
            }
          }
          this.router.navigate(['/punch-list-items'], navigationExtras);

          // this.allDispatchForLocation();
        }

      }, (err) => {
        console.log(err);
      })

    }else if(forWhat == 'streetAddress'){
      data = {
        development_site: "",
        location_type: forWhat,
        primary_supervisor: this.userData?.user?.id,
        street_address: this.locationData?.street_address,
        unit: "",
        status: "status#draft",
        GSI2PK : this.userData?.user?.id,
        GSI2SK : this.locationData?.street_address,
        primary_super_contact: this.userData?.user?.email,
        display_name: this.userData?.user?.fullname
      }

      let checkData = {
        id: this.userData?.user?.id,
        location: this.locationData?.street_address,
      }
      this.common.callApi(
        this.constant.LOCATION_CHECK,
        checkData,
        'POST',
        '',
        false,
        false,
        true
      ).subscribe(async (results: any) => {
        console.log("Check For location!", results);
        
        if(results?.length <= 0){
          this.common.callApi(
            this.constant.CREATE_LOCATION_V2,
            data,
            'POST',
            '',
            false,
            false,
            true
          ).subscribe(async (results: any) => {
            console.log("Created Successfully from app component!", results);
            this.allDispatchForCreatedLocation(results);
          }, (err) => {
            console.log(err);
          })
        }else{
          await this.store.dispatch(setLocationID(results?.location_check[0].id));
          await this.store.dispatch(setAlredyHaveLocation('yes'));

          let selected = {
            unumber: results?.location_check[0]?.unit,
            development: results?.location_check[0]?.development_site,
            address: results?.location_check[0]?.street_address,
            location_status: results?.location_check[0]?.location_status,
            primary_supervisor: results?.location_check[0]?.primary_supervisor
          }
          await this.store.dispatch(setSelectedLocation(selected));
          await this.store.dispatch(setAddressType(results?.location_check[0]?.location_type));
          let navigationExtras: NavigationExtras = {
            queryParams: {
              fromWhere: "createPunchList",
            }
          }
          this.router.navigate(['/punch-list-items'], navigationExtras);
        }

      }, (err) => {
        console.log(err);
      })
    }else if(forWhat == 'both'){
      data = {
        development_site: this.capitalizeName(this.locationData?.development_site),
        location_type: forWhat,
        primary_supervisor: this.userData?.user?.id,
        street_address: this.locationData?.street_address,
        unit: this.locationData?.unit + "",
        status: "status#draft",
        GSI2PK : this.userData?.user?.id,
        GSI2SK : this.locationData?.street_address,
        primary_super_contact: this.userData?.user?.email,
        display_name: this.userData?.user?.fullname
      }

      let checkData = {
        id: this.userData?.user?.id,
        location: this.locationData?.street_address,
      }
      this.common.callApi(
        this.constant.LOCATION_CHECK,
        checkData,
        'POST',
        '',
        false,
        false,
        true
      ).subscribe(async (results: any) => {
        console.log("Check For location!", results);
        
        if(results?.length <= 0){
          this.common.callApi(
            this.constant.CREATE_LOCATION_V2,
            data,
            'POST',
            '',
            false,
            false,
            true
          ).subscribe(async (results: any) => {
            console.log("Created Successfully from app component!", results);
            this.allDispatchForCreatedLocation(results);
          }, (err) => {
            console.log(err);
          })
        }else{
          await this.store.dispatch(setLocationID(results?.location_check[0].id));
          await this.store.dispatch(setAlredyHaveLocation('yes'));

          let selected = {
            unumber: results?.location_check[0]?.unit,
            development: results?.location_check[0]?.development_site,
            address: results?.location_check[0]?.street_address,
            location_status: results?.location_check[0]?.location_status,
            primary_supervisor: results?.location_check[0]?.primary_supervisor
          }
          await this.store.dispatch(setSelectedLocation(selected));
          await this.store.dispatch(setAddressType(results?.location_check[0]?.location_type));
          let navigationExtras: NavigationExtras = {
            queryParams: {
              fromWhere: "createPunchList",
            }
          }
          this.router.navigate(['/punch-list-items'], navigationExtras);
        }

      }, (err) => {
        console.log(err);
      })
    }
  }

  async allDispatchForLocation(locationData){
    // await this.store.dispatch(setLocationID(this.locationData.locationId));
    // await this.store.dispatch(setAlredyHaveLocation('yes'));

    // let selected = {
    //   unumber: this.locationData?.unit,
    //   development: this.locationData?.development_site,
    //   address: this.locationData?.street_address,
    //   location_status: this.locationData?.location_status,
    //   primary_supervisor: this.locationData?.primary_supervisor,
    // }

    // console.log("Ahiya Avyuuuuu", this.locationData)


    // await this.store.dispatch(setSelectedLocation(selected));
    // let typee = (this.locationData?.street_address != '' && this.locationData?.unit != '') ? "both" : (this.locationData?.street_address != '' && this.locationData?.unit != '') ? "streetAddress" : "manual"
    // await this.store.dispatch(setAddressType(typee));
    // let navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     fromWhere: "createPunchList",
    //   }
    // }
    // this.router.navigate(['/punch-list-items'], navigationExtras);

    await this.store.dispatch(setLocationID(locationData.locationId));
    await this.store.dispatch(setAlredyHaveLocation('yes'));

    let selected = {
      unumber: locationData?.unit,
      development: locationData?.development_site,
      address: locationData?.street_address,
      location_status: locationData?.location_status,
      primary_supervisor: locationData?.primary_supervisor,
    }

    console.log("Ahiya Avyuuuuu", locationData)


    await this.store.dispatch(setSelectedLocation(selected));
    let typee = (locationData?.street_address != '' && locationData?.unit != '') ? "both" : (locationData?.street_address != '' && locationData?.unit != '') ? "streetAddress" : "manual"
    await this.store.dispatch(setAddressType(typee));
    let navigationExtras: NavigationExtras = {
      queryParams: {
        fromWhere: "createPunchList",
      }
    }
    this.router.navigate(['/punch-list-items'], navigationExtras);
  }

  async allDispatchForCreatedLocation(results){
    await this.store.dispatch(setLocationID(results?.location?.id));
    await this.store.dispatch(setAlredyHaveLocation('yes'));

    let selected = {
      unumber: results?.location?.unit,
      development: results?.location?.development_site,
      address: results?.location?.street_address,
      location_status: this.locationData?.location_status,
      primary_supervisor: this.locationData?.primary_supervisor
    }
    await this.store.dispatch(setSelectedLocation(selected));
    let typee = (results?.location?.street_address != '' && results?.location?.unit != '') ? "both" : (results?.location?.street_address != '' && results?.location?.unit != '') ? "streetAddress" : "manual"
    await this.store.dispatch(setAddressType(typee));
    let navigationExtras: NavigationExtras = {
      queryParams: {
        fromWhere: "createPunchList",
      }
    }
    this.router.navigate(['/punch-list-items'], navigationExtras);
  }

  async newPunchList() {
    
    var n = this.router.url.lastIndexOf('/');
    var result = this.router.url.substring(n + 1);

    this.store.select<any>('punchItemData').subscribe(state => this.punchItemData = state);
    this.locationData = this.punchItemData.selectedPunchItemLocation;
  
    console.log(result,"result",this.locationData);

    if(result == 'active-punch-details'){
      this.openMoreInfo();
    }else if(result == 'active-punch-item-details'){
      this.openMoreInfo();
    }else if(result.includes('active-punch-trade-details')) {
      this.openMoreInfo();
    }else{
      this.router.navigate(['/tabs/create-punchlist']);
    }

  }


  checkSubscription() {
    console.log(this.userData, "USER DATA")
    this.common.callApi(
      this.constant.CHECK_USER_SUBSCRIPTION_STATUS+this.userData?.user?.userID,
      '',
      'GET',
      '',
      false,
      false,
      true
    ).subscribe(async (results: any) => {
      console.log(results, "=====")
      if(results?.user_item?.length == 0) {
        console.log(results , "Error");
        this.subscriptionError();
      } else {
        let status = results.user_item[0]?.sub_status
        if(status == 'Free Trial' || status == 'Free' || status == 'Professional'|| status == 'Premium') {
          this.newPunchList();  
        } else {
          this.subscriptionError();
        }
      }
    }, (err) => {
      
      console.log("error in get tags!", err);
    })
  }

  async subscriptionError() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      // header: 'Are you sure?',
      message: 'No subscription found',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });
    await alert.present();
  }

  readDataFromUserLocalDataTable() {
    this.store.select<any>('punchItemData').subscribe(state => this.punchItemData = state);
    this.store.select<any>('locationData').subscribe(state => this.locationData = state);
    console.log("LOCATION DATA = ", this.locationData)
    let fetchTime = this.locationData?.userLocalDataUpdate; 
    if(fetchTime == null || fetchTime == undefined) {
      fetchTime = Date.now();
    } else {
      fetchTime = this.locationData?.userLocalDataUpdate
    }

    console.log(this.punchItemData?.punchItemsStore, "PUNCH ITEMS STORE");
    let body = { 
      "user_id": this.userData?.user?.id,
      "lastFetch": this.locationData?.userLocalDataUpdate ? this.locationData?.userLocalDataUpdate+"" : Date.now()+"",
      // "lastFetch": "1682160196",
      
    }

    this.common.callApi(
      this.constant.GET_USERLOCALDATA_TABLE_LASTREAD,
      body,
      'POST',
      '',
      false,
      false,
      true
    ).subscribe((results: any) => {
      console.log("READ LOCAL DATA === ", results.data, Date.now());
      this.store.dispatch(setUserLocalDataUpdate(Date.now()))

      // let newData = this.punchItemData?.punchItemsStore;
      let newData = this.punchItemData?.punchItemsStore?.map(obj => ({...obj}))
      console.log(newData, "NEW DATA")
       newData?.forEach((element, index) => {
        
        // const filteredLocArray = results.data.filter(obj => obj.sk == element.locationId);
        const filteredLocArray = results.data.filter(obj => obj.sk?.includes(element.locationId));
        console.log(filteredLocArray,"filteredLocArray filteredLocArray filteredLocArray filteredLocArray");
        
        if(filteredLocArray?.length > 0) {
          console.log("LOCATION CHANGE",filteredLocArray)
          // element.lastPunchItemsFetch = filteredLocArray[0]?.GSI1SK;
          // element.lastPunchItemsFetch = filteredLocArray[0]?.lastPunchItemsFetch;

          filteredLocArray?.forEach((elem) => {
            console.log(element,"[][filteredLocArray][][[][filteredLocArray][][filteredLocArray][][][[][][]");
            
            if(elem?.sk.includes("lastPunchItemsFetch")){
              console.log(elem,"elem.includes(lastPunchItemsFetch) elem.includes(lastPunchItemsFetch) elem.includes(lastPunchItemsFetch) ");
              
              element.lastPunchItemsFetch = elem?.lastPunchItemsFetch;
            }
            if(elem?.sk.includes("lastProgressUpdate")){

              console.log(elem,"elem.includes(lastProgressUpdate) elem.includes(lastProgressUpdate) elem.includes(lastProgressUpdate) ");
              element.lastProgressUpdate = elem?.lastProgressUpdate;
            }
          })
        }
        element?.punchItems?.forEach((ele, i) => {
          // const filteredArray = results.data.filter(obj => obj.sk == ele.id);
          const filteredArray = results.data.filter(obj => obj.sk?.includes(ele.id));
          if(filteredArray?.length > 0) {
            const newPunchItems = [...element.punchItems];
            newPunchItems[i] = { 
              ...ele, 
              // lastRead: filteredArray[0].GSI1SK,
              lastRead: filteredArray[0].lastRead,
              lastRoomOpen: filteredArray[0].lastRoomOpen,
              hasPunchItemComment: false,
              hasTradeComment: false
            };
            element.punchItems = newPunchItems;
            console.log(newPunchItems[i], "ELELELELE")
          }
        });
        if((newData?.length - 1) == index) {
          console.log(newData, "FINAL =====");
          this.store.dispatch(setPunchItemsStore(newData))
        }
      });
    }, (err) => {
      console.log(err);
    })
  }

  getPreviousContact() {

    this.store.select<any>('locationData').subscribe(state => this.locationData = state);

    this.lastContactFetch = this.locationData.lastContactFetch;

    let previousContact = this.locationData?.previouslyUsedContacts == null ? [] : this.locationData?.previouslyUsedContacts;
    console.log(previousContact, "PREV PREV PREV PREV PREV")
    
    let currentTimestamp = Date.now();
    
    let body = {
      supervisorId: this.userData?.user?.id,
      lastContactFetch: this.lastContactFetch != null ? this.lastContactFetch : undefined,
      currentTimestamp: currentTimestamp+""
    }

    this.common.callApi(
      this.constant.GET_PREVIOUS_USED_CONTACTS_V2,
      body,
      'POST',
      '',
      false,
      false,
      true
    ).subscribe(async (results: any) => {
      console.log("Previously Used Contact", results.contacts);
      
      // await this.store.dispatch(setLastContactFetch(Date.now()+""));

      // await this.store.dispatch(setLastContactFetch("1690958159910"));

      if(previousContact == null || previousContact == undefined || previousContact?.length == 0) {
        previousContact = [];
      }

      // let prevContact = this.previousContact.concat(results.contacts);

      let prevContact = results.contacts.concat(previousContact);

      console.log(prevContact, "PREV CONTACTTT")


      const filteredArray = prevContact.filter((obj, index, self) => {
        return index == self.findIndex((o) => o.sk == obj.sk);
      });

      previousContact = filteredArray;

      console.log(previousContact, "After Concatnation");

      this.getChangedOwner(previousContact, body)
      
      // this.dynamicObjectGenerationForPrevious(previousContact);
    }, (err) => {
      console.log("error in Previously Used Contact", err);
    })
  }

  getChangedOwner(previousCont, body) {

    this.common.callApi(
      this.constant.GET_CHANGED_OWNER,
      body,
      'POST',
      '',
      false,
      false,
      true
    ).subscribe(async (results: any) => {
      console.log("Get Owner", results.contacts);

      await this.store.dispatch(setLastContactFetch(Date.now()+""));

      // await this.store.dispatch(setLastContactFetch("1690958159910"));

      console.log(previousCont, results.contacts, "===============")

      let allPrev = [];
      previousCont?.forEach((ele) => {
        
        let newData = results.contacts?.filter((x) => x.oldUserId == ele.sk);
        console.log(newData, "NEW DATTTT")
        if(newData?.length > 0) {
          let obj = {
              ...ele,
              GSI1SK: newData[0]?.sk,
              GSI2PK: newData[0]?.newUserId,
              lastUpdate: newData[0]?.sk,
              sk: newData[0]?.newUserId
          }
          allPrev.push(obj);
        } else {
          allPrev.push(ele)
        }
      })

      console.log(allPrev, "ALL PREV PUSH DATA");

      const filteredPreviousCont = previousCont?.filter((ele) => {
        return !results.contacts?.some((x) => x.oldUserId === ele.sk);
      });
        
      console.log(filteredPreviousCont, "FINALLLLL");

      this.dynamicObjectGenerationForPrevious(filteredPreviousCont);

      // await this.store.dispatch(setLastContactFetch(Date.now()+""));


      

    }, (err) => {
      console.log("error in Previously Used Contact", err);
    })
  }

  dynamicObjectGenerationForPrevious(previousContact) {
    console.log("=======DYNAMIC", previousContact);
    this.store.dispatch(setPreviouslyUsedContacts(previousContact));

    const updatedPreviousContact = previousContact.map(element => {
      const clonedElement = { ...element };
      clonedElement.displayNameLocal = clonedElement.displayName;
      clonedElement.grantSupervisour = clonedElement.is_supervisor;
      return clonedElement;
    });
    
    previousContact = updatedPreviousContact;
    console.log(previousContact, "PREVVVVV");

  }

}
