import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.page.html',
  styleUrls: ['./pricing.page.scss'],
})
export class PricingPage implements OnInit {

  cordovaa = false;
  constructor(private navCtrl : NavController,private modalCtrl : ModalController,
    private router : Router) { }

  ngOnInit() {
    if(Capacitor.isNative == true){
      console.log("cordova");
      this.cordovaa = true;
    }else{
      console.log("Cordova not available");
      this.cordovaa = false;
    }
  }

  goBack(){
    // this.navCtrl.back();
    this.modalCtrl.dismiss();
  }

  startDrive(){
    this.modalCtrl.dismiss();
    this.router.navigate(["/test-drive"])
  }
}
