import { Injectable } from '@angular/core';
import { ToastController, AlertController, LoadingController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  isLoading = false;
  constructor(
      public loadingCtrl: LoadingController,
      public toastController: ToastController
  ) { }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 5000,
      color: 'dark'
    });
    toast.present();
  }

  async present(msg) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      // duration: 5000,
      spinner: 'bubbles',
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }



  // For storage Preferences`
  async setString(key: string, value: string) {
    await Preferences.set({ key, value });
  }

  async getString(key: string): Promise<{ value: any }> {
      return (await Preferences.get({ key }));
  }
}
