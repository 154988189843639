import { ActionTypes } from "../constants/action-types";

  export const setAlertsLocations = (val) => {
    return {
      type: ActionTypes.SET_ALERTS_LOCATION,
      payload: val,
    };
  };

  export const setScheduledLocations = (val) => {
    return {
      type: ActionTypes.SET_SCHEDULED_LOCATION,
      payload: val,
    };
  };

  export const setAlertsActiveLocationsLastupdate = (val) => {
    return {
      type: ActionTypes.SET_ALERTS_ACTIVE_LOCATIONS_LASTUPDATE,
      payload: val,
    };
  };