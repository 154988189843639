// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://d51ekulg32.execute-api.us-east-2.amazonaws.com/development/",
  fileUploadUrl: "https://9a0ltixa28.execute-api.us-east-2.amazonaws.com/",
  mandatorySignIn: false,
  region: "us-east-2",
  userPoolId: "us-east-2_FB3hBOITb",
  identityPoolId: "us-east-2:bc6b22b4-f834-49be-a127-4b8364d218f7",
  userPoolWebClientId: "4scd9mtnd7fv2biq0a78vnekkn",
  storage: sessionStorage,

  // baseUrl2: "https://fg5zt0v3dg.execute-api.us-east-1.amazonaws.com/dev/"
  // baseUrl2: "https://uladgbqc9k.execute-api.us-east-1.amazonaws.com/dev/"
  // baseUrl2: "https://2tfycfenlk.execute-api.us-east-1.amazonaws.com/dev/"
  baseUrl2: "https://avlsgvbq02.execute-api.us-east-1.amazonaws.com/dev/"
  
  // baseUrl2: "http://localhost:3000/dev/"

  // production: false,
  // baseUrl : "https://d51ekulg32.execute-api.us-east-2.amazonaws.com/development/",
  // mandatorySignIn: false,
  // region: "us-east-2",
  // userPoolId: "us-east-2_RZY4M8oAT",
  // identityPoolId: "us-east-2:b0999cc8-be45-4fd4-9460-48c5fc93e926",
  // userPoolWebClientId: "ko7skda3si5adhlephv4orv5d",
  // storage: sessionStorage,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
