import { Injectable } from "@angular/core";
import {
  CanActivate,
} from "@angular/router";
import { NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ApiService } from "./services/api/api.service";
import { Events } from "./services/events";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  platformm  :any;
  auth  :any;

  constructor(private apiService: ApiService,
    private navCtrl : NavController) {}
  canActivate(): boolean { 

    if (!this.apiService.getToken()) {
      this.navCtrl.navigateRoot("/home");
      return false;
    }else{
      
      return this.apiService.getToken();
    }
  }
}
