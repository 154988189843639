import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  // ADD_FULLNAME = "users/register"
  USER_DETAIL = "users/user/"
  CREATE_ADNACE_ALERT_PREFERENCES = "advanced_alert_preferences"
  GET_ADVANCE_ALERT_PREFERENCES = "advanced_alert_preferences/user/"
  UPDATE_ADVANCED_ALERT_PREFERENCES = "advanced_alert_preferences/"

  GET_ALL_LOCATION = "location/supervisor_id/"
  GET_LOCATION_BY_SUPERVISOR = "location/supervisor/"
  GET_LOCATION_BY_USER_ID = "location/"
  CREATE_LOCATION = "location"
  GET_SAVED_LOCATION_SITE = "location/user/"
  CREATE_SAVED_LOCATION_SITE = "location/user"
  GET_DRAFT_BY_SUPERVISOR = "location/supervisor/draft/"
  GET_ROOM_DRAFT_DRAFT_BY_LOCATION = "punchlist/summary/draft/"
  UPDATE_LOCATION_STATUS = "location/"

  GET_TRADE = "trades"
  GET_ROOMS = "rooms/user/"
  GET_TAGS = "tags/user/"
  GET_DESCRIPTIONS = "trades/description/"
  GET_MATERIAL_ONSITE = "material/site"
  GET_PUNCHITEM_BY_LOCATION = "punchlist/items/location/"
  CREATE_PUNCHITEM_DRAFT = "punchlist/item"
  UPDATE_PUNCHITEM_DRAFT = "pupunchlist/item/"
  CREATE_ROOM = "rooms/room"
  CREATE_DESCRIPTION = "trades/description"
  CREATE_TAGS = "tags/tag"
  CREATE_PUNCHITEM_TAG = "tags/punchitem"
  GET_PUNCHITEM_TAGS = "tags/punchitem/"
  DELETE_PUNCHITEM = "punchlist/item/"
  DELETE_PUNCHITEM_TAG = "tags/punchitem/"
  GET_PUNCHITEM_SUMMARY = "punchlist/summary/"
  CREATE_MEDIA = "media"
  GET_MEDIA = "media/"
  DELETE_MEDIA = "media/"
  UPLOAD_IMAGE = "upload"

  GET_LOCATION_NOTES = "location/notes/"
  CRAETE_LOCATION_NOTES = "location/notes/note"
  DELETE_LOCATION_NOTES = "location/notes/note/"
  EDIT_LOCATION_NOTES = "location/notes/note/"

  GET_LOCATION_SUGGETION_DATES = "location/suggetion/dates/"
  EDIT_LOCATION_SUGGETION_DESCRIPTION = "location/suggetion/dates/"
  GET_LOCATION_DATES = "location/dates/"
  CRAETE_LOCATION_DATES = "location/dates"
  DELETE_LOCATION_DATES = "location/dates/"
  EDIT_LOCATION_DATES = "location/dates/"

  GET_JOBSITE_CONTACT_BY_LOCATION_USER = "location/jobsite/locationuser/"
  GET_JOBSITE_CONTACT_BY_LOCATION = "location/jobsite/contacts/"
  GET_PREVIOUSLY_USED_JOBSITE_CONTACT_BY_LOCATION = "location/jobsite/contacts/previous/"
  DELETE_PREVIOUSLY_USED_JOBSITE_CONTACT = "location/jobsite/contacts/"
  CREATE_JOBSITE_CONTACT = "location/jobsite/contacts"
  UPDATE_JOBSITE_CONTACT = "location/jobsite/contacts/"

  GET_OCCUPIED_CONTACT_BY_LOCATION = "location/occupied/contact/"
  CREATE_OCCUPIED_CONTACT = "location/occupied/contact"
  DELETE_OCCUPIED_CONTACT = "location/occupied/contact/"
  CHECK_USER_MOBILE = "users/check/mobile"
  UPDATE_OCCUPIED_CONTACT = "location/occupied/contact/"
  CHECK_USER_REGISTERED = "users/check"
  LINK_USER = "location/jobsite/contacts/link"
  SEND_OTP_SMS = "twilio/message"

  CREATE_TRADE_CONTACT = "location/trade/contacts"
  GET_TRADE_CONTACT = "location/trade/contacts/"
  GET_SINGLE_TRADE_CONTACT = 'location/trade/contacts/'
  DELETE_SINGLE_TRADE_CONTACT = 'location/trade/contacts/'
  UPDATE_TRADE_CONTACT = 'location/trade/contacts/'

  GET_PREVIOUSLY_USED_TRADE_CONTACT = 'location/trade/contacts/previous/'

  GET_GLOBAL_CONTACTS = 'location/globalcontact/'

  GET_GLOBAL_REGISTER_USER_BY_SUPERVISOR_ID = '/location/globalcontact/registered/'
  GET_GLOBAL_UNREGISTER_USER_BY_SUPERVISOR_ID = '/location/globalcontact/unregistered/'
  UPDATE_GLOBAL_CONTACT = 'location/globalcontact/detail/'

  // VERSION 2
  CHECK_USER_REGISTERED_V2 = "users/check"
  VERIFY_CODE_V2 = "user/confirm"
  VERIFY_CODE_RESEND_V2 = "user/confirm/resend"
  REGISTER_V2 = "users/register"
  CREATE_USER_V2 = "users"
  LOGIN_V2 = "users/login"
  USER_DETAIL_V2 = "user/"
  CREATE_ALERT_V2 = "user/alert"
  CREATE_ADNACE_ALERT_PREFERENCES_V2 = "user/advacnedAlert"
  USER_FILTER = "user/filter"
  USER_UPDATE_FILTER = "user/filter"
  CREATE_LOCATION_V2 = "location"
  GET_ALL_LOCATION_V2 = "location/user/"
  GET_ALL_LOCATION_OF_SUPERVISOR_V2 = "location/user/allsuper/"
  GET_TRADE_V2 = "trades"
  GET_DESCRIPTIONS_V2 = "tradeDescription/"
  CREATE_DESCRIPTION_V2 = "tradeDescription"
  GET_GLOBAL_ROOMS_V2 = "rooms/global"
  GET_ROOMS_BY_USER_V2 = "rooms?id="
  CREATE_ROOM_V2 = "room"
  GET_MATERIAL_ONSITE_V2 = "materials"
  GET_TAGS_V2 = "tag/"
  CREATE_TAGS_V2 = "tag"
  GET_PUNCHITEM_TAGS_V2 = "punchItemTags/"
  CREATE_PUNCHITEM_TAG_V2 = "punchItemTags"
  CREATE_PUNCHITEM_DRAFT_V2 = "punchItem"
  UPDATE_PUNCH_ITEM_V2 = "punchItem/filter"
  GET_PUNCHITEM_BY_LOCATION_V2 = "punchItem/location/"
  DELETE_PUNCHITEM_V2 = "punchItem/"
  PUNCH_ITEM_FILTER_V2 = "punchItem/filter"
  DELETE_PUNCHITEM_TAG_V2 = "punchItemTag/"
  // CREATE_JOBSITE_CONTACT_V2 = "location/filter"
  // UPDATE_JOBSITE_CONTACT_V2 = "location/filter"
  CREATE_JOBSITE_CONTACT_V2 = "location/filter/user"
  UPDATE_JOBSITE_CONTACT_V2 = "location/filter/user"
  UPDATE_PREVIOUSLY_USED_CONTACT_V2 = "previousContact/updateStatus"
  GET_JOBSITE_CONTACT_BY_LOCATION_USER_V2 = "location/jobsite/filter"
  CRAETE_LOCATION_DATES_V2 = "location/filter"
  GET_LOCATION_DATES_V2 = "location/filter"
  EDIT_LOCATION_DATES_V2 = "location/filter"
  EDIT_PLAN_STATUS_V2 = "location/filter"
  CRAETE_LOCATION_NOTES_V2 = "location/filter"
  GET_LOCATION_NOTES_V2 = "location/filter"
  EDIT_LOCATION_NOTES_V2 = "location/filter";
  CHECK_NUMBER = "twilio/lookup"
  DELETE_LOCATION_DATES_V2 = "location/delete"
  DELETE_LOCATION_NOTES_V2 = "location/delete"
  DELETE_OCCUPIED_CONTACT_V2 = "location/delete"
  SEND_OTP_SMS_V2 = "twilio/message"
  GET_TRADE_CONTACT_V2 = "location/filter"
  // CREATE_TRADE_CONTACT_V2 = "location/filter"
  // UPDATE_TRADE_CONTACT_V2 = "location/filter"
  CREATE_TRADE_CONTACT_V2 = "location/filter/user"
  UPDATE_TRADE_CONTACT_V2 = "location/filter/user"
  GET_SPECIFIC_TRADE_CONTACT_V2 = "location/filter"
  GET_SPECIFIC_TRADE_PRIMARY_CONTACT_V2 = "location/trade/primary"
  GET_SPECIFIC_TRADE_CONTACT_WITH_IS_REGISTER_V2 = "location/filter/register"
  GET_PREVIOUSLY_USED_JOBSITE_CONTACT_BY_LOCATION_V2 = "location/filter"
  GET_PREVIOUSLY_USED_TRADE_CONTACT_V2 = 'location/filter'
  DELETE_PREVIOUSLY_USED_JOBSITE_CONTACT_V2 = "location/delete"
  DELETE_SINGLE_TRADE_CONTACT_V2 = 'location/delete'
  CREATE_OCCUPIED_CONTACT_V2 = "location/filter"
  GET_OCCUPIED_CONTACT_BY_LOCATION_V2 = "location/filter"
  UPDATE_LOCATION_STATUS_V2 = "location/filter"
  UPDATE_LOCATION_TRADE = "location/filter"
  GET_LOCATION_TRADE = "location/filter"
  CHECK_USER_REGISTRATION_V2 = "users/check/registration"
  GET_LOCATION_TAGS = "location/filter"
  UPDATE_LOCATION_TAG = "location/filter"
  GET_PUNCHITEM_SUMMARY_V2 = "punchItem/filter/location/"
  DELETE_COMMENT_V2 = "punchItem/comment/delete"
  INVOKE_REQUEST_LOCATION = "invoke/location"
  INVOKE_LOCATION_PI_STATUS = "invoke/locationPiStatusUpdate"
  INVOKE_REQUEST_TRADE = "invoke/trade"
  INVOKE_REQUEST_JOBSITE_CONTACT = "invoke/jobsiteContact"
  INVOKE_REQUEST_TRADE_CONTACT = "invoke/tradeContact"
  INVOKE_REQUEST_PUNCHITEM = "invoke/punchItem"
  LOCATION_CHECK = "location/check"
  CREATE_USER_SUBSCRIPTION = "users/item"
  CHECK_USER_SUBSCRIPTION_STATUS = "users/item/"

  EDIT_LOCATION_AND_TRADE_COMMENTS_V2 = "invoke/locationComments"
  DELETE_MEDIA_V2 = "punchItemMedia"
  GET_DRAFT_PUNCHITEM_SUMMARY_V2 = "punchItemDraft/filter/location/"
  UPDATE_LOCATION_TYPE_STATUS_V2 = "location/filter"
  GET_LOCATION_COMMENTS_V2 = "location/filter"
  ADD_USER_COMMENT = "userLocalData/filter"
  USER_VISITED_COMMENT = "userLocalData/visited"
  PUNCH_ITEM_LOCATION_COMMENTS = "punchItem/location/comments"
  GET_PUNCHITEM_SUMMARY_TRADE_USER_V2 = "punchItem/filter/location/trade"
  PUNCH_ITEM_LOCATION_COMMENTS_TRADE_USER = "location/trade/comments"
  UPDATE_USERLOCALDATA_TABLE_LASTREAD = "userLocalData/lastread"
  UPDATE_USERLOCALDATA_TABLE_LASTROOMOPEN = "userLocalData/lastRoomOpen"
  UPDATE_USERLOCALDATA_TABLE_LASTPROGRESSUPDATE = "userLocalData/lastProgressUpdate"
  UPDATE_USERLOCALDATA_TABLE_LAST_PUNCHITEM_FETCH = "userLocalData/lastPunchItemFetch"
  GET_USERLOCALDATA_TABLE_LASTREAD = "userLocalData/readData"
  GET_SUPERVISOR_COMMENTS = "location/supervisor/comments"
  EDIT_PUNCH_ITEM_LAST_COMMENT = "invoke/itemComment"
  EDIT_TRADE_COMMENT = "invoke/tradeComments"
  
  INVOKE_NEW_COMMENTS = "invoke/newComments"
  CREATE_NEW_PLAN = "location/create-plan"
  UPDATE_USERLOCALDATA_TABLE_LASTFETCH = "userLocalData/lastFetch"
  CREATE_PUNCH_ITEM_TO_PLAN = "punchItem/add-to-plan"
  GET_LOCATION_DRAFT_PLAN = "location/plans/"
  ADD_PLAN_USERS = "invoke/newPlanSent"
  ACTIVE_ALL_DRAFT_PLANS = "location/activate-all-plan"
  GET_ALL_ACTIVE_PLANS_ITEMS = "location/active-plans"
  UPDATE_UNREAD_COMMENTS = "location/unreadComments"
  UPDATE_BLUE_DOTS = "location/unreadBlueDots"

  GET_PUNCH_ITEM_BY_ID = "punchItem/"
  UPDATE_LOCATION_STATUS_CONTACT = "invoke/updateLocation"

  ADD_CONTACT_TO_PREVIUOSLY_USED_CONTACT = "addPreviousContact"
  GET_PREVIOUS_USED_CONTACTS_V2 = "previousContacts/filter"
  CREATE_JOBSITE_FROM_PREVIOUS = "location/createJobsiteContactFromPrevious"
  DELETE_JOBSITE_CONTACT_V2 = "location/delete"
  DELETE_PREVIOUSLY_USED_CONTACT = "delete/previouslyUsedContact"

  ADD_JOBSITE_CONTACT_USING_SNS = "invoke/addJobsiteContact"
  ADD_TRADE_CONTACT_USING_SNS = "invoke/addTradeContact"
  ADD_TRADE_CONTACT_MULTIPLE_USING_SNS = "invoke/addTradeContactMultiple"
  GET_PREVIOUS_USED_TRADE_CONTACTS_V2 = "previousTradeContacts/filter"
  DELETE_PREVIOUSLY_USED_TRADE_CONTACT = "delete/previouslyUsedTradeContact"
  DELETE_TRADE_CONTACT_V2 = "location/delete"

  ADD_LINKED_EMAIL = "addLinkedEmail"
  INVOKE_CHNAGE_CONTACT_OWNER = "invoke/changeContactOwner"
  GET_CHANGED_OWNER = "getChangedOwner/filter"
  VERIFICATION_EMAIL_SENT = "verificationEmail/send"

  INVOKE_MATERIAL_ETA_DATE = "/invokeMaterialEtaDate"
  INVOKE_SCHEDULE_DATE = "/invokeScheduleDate"
  GET_ACTIVITY_SCHEDULED_BY_USERID = "activity/"
  INVOKE_COMPOSE_MASSAGE_NEW_PUNCH = "invoke/composeMessageNewPunch"
  INVOKE_COMPOSE_MASSAGE = "invoke/composeMessage"
  FETCH_TEAM_MEMBERS = "location/teamMembers/";



  constructor() { }
}
