import { ActionTypes } from "../constants/action-types";

export const setRoomData = (val) => {
  return {
    type: ActionTypes.SET_ROOM_DATA,
    payload: val,
  };
};

export const setIsChangeRoom = (val) => {
  return {
    type: ActionTypes.SET_IS_ROOM_CHANGE,
    payload: val,
  };
};
