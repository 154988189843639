import { ActionTypes } from "../constants/action-types";
const initialState = {
};


export function locationReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.SET_ALL_USER_LOCATION:
            return { ...state, allUserLocationData: payload };
        case ActionTypes.SET_LOCATION:
            return { ...state, locationData: payload };
        case ActionTypes.SET_SELECTED_LOCATION:
            return { ...state, selcetedLocation: payload };
        case ActionTypes.SET_LOCATION_ID:
            return { ...state, locationid: payload };
        case ActionTypes.SET_ALREADY_HAVE_LOCATION:
            return { ...state, alreadyhavelocation: payload };
        case ActionTypes.SET_ADDRESS_TYPE:
            return { ...state, addressType: payload };
        case ActionTypes.SET_ACTIVE_LOCATIONS:
            return { ...state, activeLocations: payload };
        case ActionTypes.SET_ACTIVE_LOCATIONS_LASTUPDATE:
            return { ...state, activeLocationsLastupdate: payload };
        case ActionTypes.SET_LOATION_SORTING:
            return { ...state, locationSorting: payload };
        case ActionTypes.SET_VISITED_LOCATION:
            return { ...state, visitedLocation: payload };
        case ActionTypes.SET_USER_LOCAL_DATA_UPDATE:
            return { ...state, userLocalDataUpdate: payload };
        case ActionTypes.LAST_CONTACT_FETCH:
            return { ...state, lastContactFetch: payload };
        case ActionTypes.PREVIOUSLY_USED_CONTACTS:
            return { ...state, previouslyUsedContacts: payload };
        case ActionTypes.SET_LOCATION_JOBSITE_CONTACTS:
            return { ...state, allLocationJobsiteContacts: payload };
        case ActionTypes.SET_LOCATION_IMPORTANT_DATES:
            return { ...state, allLocationaImportantDates: payload };
        default:
            return state;
    }
}