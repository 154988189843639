import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ConstantService } from '../constant/constant.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LoadingOptions } from '@ionic/core';
import { LoadingController } from '@ionic/angular';
import { UtilService } from '../util/util.service';
import { environment } from 'src/environments/environment';
import { catchError, finalize, map } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { setAddressType, setAlredyHaveLocation, setLocation, setLocationID, setSelectedLocation } from 'src/store/actions/location';
import { setAuthentication } from 'src/store/actions/authenticate';
import { setNavigationParameter } from 'src/store/actions/navigationVariables';
import { setUser } from 'src/store/actions/user';
import { AuthenticationService } from '../auth/authentication.service';
import Amplify, { Auth } from 'aws-amplify';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private loading!: HTMLIonLoadingElement;
  SERVICE_URL: string;
  FILE_UPLOAD_URL: string;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  auth: any;
  userData: any;

  constructor(
    public constant: ConstantService,
    public http: HttpClient,
    public loadingController: LoadingController,
    public utility: UtilService,
    private router: Router,
    private store: Store<any>,
    private authServices: AuthenticationService
  ) {
    this.loadToken();
    this.apiInitConfig();
  }

  ionViewWillEnter() {
    //   get Token From Redux
    this.store.select<any>('auth').subscribe(state => this.auth = state);
    console.log(this.auth, "in enter")
  }

  apiInitConfig(): void {
    // this.SERVICE_URL = environment.baseUrl;
    this.SERVICE_URL = environment.baseUrl2;
    this.FILE_UPLOAD_URL = environment.fileUploadUrl;
  }

  /* Auth Guard Services */
  async loadToken() {
    this.store.select<any>('auth').subscribe(state => this.auth = state);
    const token = await this.auth?.authenticationData?.token?.sessionToken;
    if (token) {
      this.token = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  async initiateAuthentiCation() {
    this.isAuthenticated.next(true);
  }

  async logoutAuthentication() {
    this.isAuthenticated.next(true);
  }

  private logging(requestUrl: any, requestType: any): void {
    this.store.select<any>('auth').subscribe(state => this.auth = state);
    console.log(`Request URL => ${this.SERVICE_URL}${requestUrl}`);
    console.log(`Request Type => ${requestType}`);
    console.log(
      `Token => ${this.auth?.authenticationData?.token?.sessionToken}`
    );
  }

  private headerInit(): HttpHeaders {
    let headers: any = new HttpHeaders();
    this.store.select<any>('auth').subscribe(state => this.auth = state);
    // console.log("header init ni andar",this.auth?.authenticationData?.token?.sessionToken)
    if (this.auth?.authenticationData?.token?.sessionToken) {
      headers = headers.append('Authorization', 'Bearer ' + this.auth?.authenticationData?.token?.sessionToken);
    }

    return headers;
  }

  private showLoader(isShowLoading: any, loadingText: any): Promise<void> {
    if (isShowLoading) {
      return this.presentLoader(loadingText);
    }
    return null;
  }

  async presentLoader(_msg?: string): Promise<void> {
    const opts: LoadingOptions = {
      spinner: "bubbles",
      translucent: true,
      cssClass: "my-loading-class",
      duration: 500,
      backdropDismiss: true,
      animated: true,
      keyboardClose: true,
      message: _msg || "Please wait...",
      showBackdrop: true,
    };
    this.loading = await this.loadingController.create(opts);
    return this.loading.present();
  }

  dismissLoader(): Promise<boolean> {
    if (!this.loading) {
      return;
    }
    return this.loading.dismiss();
  }

  callApi(
    requestUrl: any,
    requestParams: any,
    requestType: any,
    loadingText: any,
    showLoading: Boolean,
    log: Boolean,
    passHeaderToken: Boolean,
  ): Observable<any> {

    this.showLoader(showLoading, loadingText);

    let headers: any
    passHeaderToken ? headers = this.headerInit() : null;
    log ? this.logging(requestUrl, requestType) : null;

    if (requestType == "GET") {
      // this.refreshToken();
      return this.callGETRequest(requestUrl, headers);
    }

    if (requestType == "POST") {
      return this.callPOSTRequest(requestUrl, requestParams, headers);
    }

    if (requestType == "PUT") {
      return this.callPUTRequest(requestUrl, requestParams, headers);
    }

    if (requestType == "DELETE") {
      return this.callDELETERequest(requestUrl, headers);
    }
  }

  callDELETERequest(requestUrl: any, headers: HttpHeaders): Observable<any> {
    this.refreshToken();
    return this.http
      .delete<any>(this.SERVICE_URL + requestUrl, { headers })
      .pipe(
        finalize((): void => {
          // Hide the loading spinner on success or error
          this.dismissLoader();
        }),
        map((res: any): any => {
          this.dismissLoader();
          return res;
        }),
        catchError(this.handleError("error", []))
      );
  }

  callPUTRequest(requestUrl: any, requestParams: any, headers: HttpHeaders): Observable<any> {
    this.refreshToken();
    return this.http
      .put<any>(this.SERVICE_URL + requestUrl, requestParams, {
        headers,
      })
      .pipe(
        finalize((): void => {
          // Hide the loading spinner on success or error
          this.dismissLoader();
        }),
        map((res: any): any => {
          this.dismissLoader();
          return res;
        }),
        catchError(this.handleError("error", []))
      );
  }

  callPOSTRequest(requestUrl: any, requestParams: any, headers: HttpHeaders): Observable<any> {
    this.refreshToken();
    return this.http
      .post<any>(this.SERVICE_URL + requestUrl, requestParams, {
        headers,
      })
      .pipe(
        finalize((): void => {
          // Hide the loading spinner on success or error
          this.dismissLoader();
        }),
        map((res: any): any => {
          this.dismissLoader();
          return res;
        }),
        catchError(this.handleError("error", []))
      );
  }

  callGETRequest(requestUrl: any, headers: HttpHeaders): Observable<any> {
    this.refreshToken();
    return this.http
      .get<any>(this.SERVICE_URL + requestUrl, { headers })
      .pipe(
        finalize((): void => {
          this.dismissLoader();
        }),
        map((res: any): any => {
          this.dismissLoader();
          return res;
        }),
        catchError(this.handleError("error", []))
      );
  }

  customGETApi(url: any) {
    return this.http
      .get<any>(url)
      .pipe(
        finalize((): void => {
          this.dismissLoader();
        }),
        map((res: any): any => {
          this.dismissLoader();
          return res;
        }),
        catchError(this.handleError("error", []))
      );
  }

  async refreshToken() {
    const currentSession = await Auth.currentSession()
    // console.log(currentSession.getIdToken().getJwtToken(), "-=-=-=-=-=-=-=-=-=-=-=current-=-=-=-=-=-=-=-=-")
    // console.log(currentSession.getIdToken().getExpiration(), "-=-=-=-=-=-=-=-=-=-=-=current-=-=-=-=-=-=-=-=-")
    const date = moment.unix(currentSession.getIdToken().getExpiration())
    const now = moment()
    const end = date
    const duration = moment.duration(end.diff(now))
    console.log(duration.asMinutes(), 'durationnnn')
    if (duration.asMinutes() <= 0) {
      console.log("expire thayu")
      let cognitoUser = await Auth.currentAuthenticatedUser()
      cognitoUser.refreshSession(currentSession.getRefreshToken().getToken(), async (err, session) => {
        console.log('session', err, session)
        console.log(session?.idToken?.jwtToken);

        let accessToken = session?.accessToken?.jwtToken;
        let sessionToken = session?.idToken?.jwtToken;
        let refreshToken = session?.refreshToken?.jwtToken;

        // set userData and token into Redux
        let uData = {
          token: {
            accessToken: accessToken,
            sessionToken: sessionToken,
            refreshToken: refreshToken
          },
          cognitoUser: {
            attributes: {
              email: this.auth?.authenticationData?.cognitoUser?.attributes?.email,
              sub: this.auth?.authenticationData?.cognitoUser?.attributes?.sub
            }
          },
          login: true
        }
        await this.store.dispatch(setAuthentication(uData));
      })
    }
  }

  uploadImage(url, data) {
    var formData = new FormData();
    formData.append("image", data.file)
    formData.append("location", data.location);
    return this.http.post(this.FILE_UPLOAD_URL + url, formData, {});
    // return this.http.post(this.SERVICE_URL + url, formData, {});
  }

  private handleError<T>(
    _operation = "operation",
    _result?: T
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      if (error.status == 401) {
        console.log("token refresh thauy++++++++++++++++++++++++++++++++++++++++")
        // this.refreshToken();
        //   this.authServices.GetAuthenticationToken().then(async (tokenRes: any) => {
        //     console.log(tokenRes, "for token");

        //     // signIn
        //     if (tokenRes) {
        //       let accessToken = tokenRes.accessToken.jwtToken;
        //       let sessionToken = tokenRes.idToken.jwtToken;
        //       let refreshToken = tokenRes.refreshToken.token;

        //       // set userData and token into Redux
        //       let uData = {
        //         token: {
        //           accessToken: accessToken,
        //           sessionToken: sessionToken,
        //           refreshToken: refreshToken
        //         },
        //         cognitoUser: {
        //           attributes: {
        //             email: this.auth?.authenticationData?.cognitoUser?.attributes?.email,
        //             sub: this.auth?.authenticationData?.cognitoUser?.attributes?.sub
        //           }
        //         },
        //         login: true
        //       }
        //       await this.store.dispatch(setAuthentication(uData));
        //     }
        //   }, (err) => {
        //     console.log(err, "error");
        //   })

        // localStorage.clear();
        // this.store.dispatch(setLocation(null));
        // this.store.dispatch(setSelectedLocation(null));
        // this.store.dispatch(setLocationID(null));
        // this.store.dispatch(setAddressType(null));
        // this.store.dispatch(setAlredyHaveLocation(null));
        // this.store.dispatch(setAuthentication(null));
        // this.store.dispatch(setNavigationParameter(null));
        // this.store.dispatch(setUser(null));
        // this.utility.presentToast("Unauthorized Request!");
        // this.router.navigateByUrl('/home', { replaceUrl: true });
      }
      this.dismissLoader();
      return throwError(error);
    };
  }
}
