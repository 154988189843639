import { ActionTypes } from "../constants/action-types";
const initialState = {
    isChanged : false
};


export function tradeReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.SET_SELECTED_TRADE:
            return { ...state, selectedTrades: payload };
        case ActionTypes.SET_CURRENT_TRADE_INDEX:
            return { ...state, currentIndex: payload };
        case ActionTypes.SET_TRADE_DATA:
            return { ...state, globalTrade: payload };
        case ActionTypes.SET_IS_TRADE_CHANGE:
            return { ...state, isChanged: payload };
        default:
            return state;
    }
}