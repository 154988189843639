import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import {
  getAllDataFromLocalForage,
  default as localForage,
} from 'ngrx-store-persist';
import "hammerjs";


if (environment.production) {
  enableProdMode();
}

getAllDataFromLocalForage({
  // driver: localForage.INDEXEDDB,
  keys: [
    'auth',
    'user',
    'navigationData',
    'locationData',
    'tradeData',
    'punchItemData',
    'filterData',
    'roomData',
    'descriptionData',
    'developmentData',
    'treeData',
    'planDetail',
    'alertsData'
  ],
}).then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
    
    // Call the element loader after the platform has been bootstrapped
    defineCustomElements(window);
});

  