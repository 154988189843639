import { Component, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { ForgotPasswordPage } from 'src/app/pages/forgot-password/forgot-password.page';
import { SignupPage } from '../signup/signup.page';
import { Events } from 'src/app/services/events';
import { Keyboard } from '@capacitor/keyboard';
import { setAuthentication } from "../../../store/actions/authenticate";
import { setUser } from "../../../store/actions/user";
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ConstantService } from 'src/app/services/constant/constant.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  myForm: UntypedFormGroup;
  submitted = false;
  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';
  loading = false;
  unauthorize = false;
  confirmUser = false;
  userNotfound = false;
  loginFeild = true;
  continue = false;
  selectedEmail = '';

  @ViewChild('inputEmail', { static: false }) inputEmail;
  @ViewChild('inputPassword', { static: false }) inputPassword;
  // @ViewChild('btn_verified1', { static: false }) btn_verified1;

  constructor(private modalCtrl: ModalController,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private authServices: AuthenticationService,
    private common: CommonService,
    private constant: ConstantService,
    private store: Store<any>,
    private events: Events) {
  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]{1,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.][a-zA-Z]{1,4}) *$')]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    })
  }

  ionViewWillEnter() {
    setTimeout(() => {
      console.log("avyu")
      this.inputEmail.setFocus();
      if (Capacitor.platform !== "web") {
        Keyboard.show();
      }
    }, 1000)
  }

  async forgotPassword() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: ForgotPasswordPage,
      cssClass: 'modal_login',
      backdropDismiss: false
    });
    return await modal.present();
  }

  blurEmail() {
    this.userNotfound = false;
    this.loginFeild = false;
  }

  emailFocus() {
    this.loginFeild = true;
  }

  blurPassword() {
    this.unauthorize = false;
    this.confirmUser = false;
  }

  passwordFocus() {
    this.unauthorize = false;
    this.confirmUser = false;
  }

  hideShowPassword() {
    console.log("Hiiii");
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
    console.log(this.passwordType, "this.passwordTypethis.passwordTypethis.passwordType")
  }

  checkForHideShow(passwordType){
    if(passwordType == 'text'){
      return "Hide"
    }else{
      return "Show"
    }
  }

  goBack() {
    setTimeout(() => {
      this.modalCtrl.dismiss();
    }, 300)
  }

  async signup() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: SignupPage,
      cssClass: 'modal_login',
      backdropDismiss: false
    });
    return await modal.present();
  }

  async signupFromEmail() {

    localStorage.setItem("emailFromLogin", this.myForm.controls.email.value);
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: SignupPage,
      cssClass: 'modal_login',
      backdropDismiss: false
    });
    return await modal.present();
  }

  get errorCtr() {
    return this.myForm.controls;
  }

  continueClicked() {
    this.submitted = true;
    if (!this.myForm.controls.email.valid) {
      console.log('Email not valid.')
      return false;
    } else {
      this.submitted = false;
      let dataMobile = {
        type: "email",
        value: this.myForm.controls.email.value
      }
      this.loading = true;
      this.common.callApi(
        this.constant.CHECK_USER_REGISTERED_V2,
        dataMobile,
        'POST',
        '',
        false,
        false,
        true
      ).subscribe(async (results: any) => {
        console.log("checkDataMobile === ", results);
        if (results.status == 200) {
          this.loading = false;
          this.userNotfound = false;
          this.continue = true;
          this.selectedEmail = this.myForm.controls.email.value;
          setTimeout(() => {
            console.log("avyu")
            this.inputPassword.setFocus();
            if (Capacitor.platform !== "web") {
              Keyboard.show();
            }
          }, 1000)
          this.myForm.controls.password.setValue("");
          console.log("email exist");
        } else {
          console.log("email not exist");
          this.userNotfound = true;
          this.loading = false;
        }
        // if (results.Items.length == 0) {
        //   console.log("email not exist");
        //   this.userNotfound = true;
        //   this.loading = false;
        // } else {
        //   this.loading = false;
        //   this.userNotfound = false;
        //   this.continue = true;
        // this.selectedEmail = this.myForm.controls.email.value;
        //   this.myForm.controls.password.setValue("");
        //   console.log("email exist");
        // }
      }, (err) => {
        // this.userNotfound = false;
        this.loading = false;
        console.log("error in checkDataMobile", err.error.message);
        if (err.error.message = "User Not Exist") {
          this.userNotfound = true;
        }
      })
    }
  }

  notYou() {
    this.continue = false;
    this.userNotfound = false;
    this.unauthorize = false;
  }

  async onSubmit() {
    this.submitted = true;
    if (!this.myForm.valid) {
      console.log('All fields are required.')
      return false;
    } else {
      this.loading = true;
      let dataMobile = {
        email: this.myForm.value.email,
        password: this.myForm.value.password
      }

      this.loading = true;
      this.common.callApi(
        this.constant.LOGIN_V2,
        dataMobile,
        'POST',
        '',
        false,
        false,
        false
      ).subscribe(async (results: any) => {
        console.log("Login === ", results);
        if (results.user.hasOwnProperty("idToken")) {
          this.loading = false;
          this.userNotfound = false;
          this.continue = true;
          this.selectedEmail = this.myForm.controls.email.value;
          // this.myForm.controls.password.setValue("");
          console.log("email exist");

          let accessToken = results.user.accessToken.jwtToken;
          let sessionToken = results.user.idToken.jwtToken;
          let refreshToken = results.user.refreshToken.token;
          let sub = results.getUserDetail[0].id
          let email = results.user.idToken.payload.email

          let uData = {
            token: {
              accessToken: accessToken,
              sessionToken: sessionToken,
              refreshToken: refreshToken
            },
            cognitoUser: {
              attributes: {
                email: email,
                sub: sub
              }
            },
            login: true
          }
          await this.store.dispatch(setAuthentication(uData));

          this.events.publish('loginn', {
            login: true,
          });

          this.getUserDetail(sub);

        }
        else {
          console.log("email not exist");
          this.userNotfound = true;
          this.loading = false;
        }
        // if (results.Items.length == 0) {
        //   console.log("email not exist");
        //   this.userNotfound = true;
        //   this.loading = false;
        // } else {
        //   this.loading = false;
        //   this.userNotfound = false;
        //   this.continue = true;
        //   this.selectedEmail = this.myForm.controls.email.value;
        //   this.myForm.controls.password.setValue("");
        //   console.log("email exist");
        // }
      }, (err) => {
        if (err?.error?.error?.name == 'UserNotConfirmedException') {
          this.confirmUser = true;
          this.loading = false;
        } else if (err?.error?.error?.name == 'NotAuthorizedException') {
          this.unauthorize = true;
          this.loading = false;
        }
      })

      // await this.authServices.signIn(this.myForm.value.email, this.myForm.value.password).then(async (signInres: any) => {
      //   console.log(signInres, "ahi ahiu ahi");

      //   if (signInres == 'UserNotConfirmedException') {
      //     this.confirmUser = true;
      //     this.loading = false;
      //   } else {
      //     if (signInres != 'NotAuthorizedException') {
      //       this.confirmUser = false;
      //       await this.authServices.GetAuthenticationToken().then(async (tokenRes: any) => {
      //         console.log(tokenRes, "for token");

      //         localStorage.setItem("password", this.myForm.value.password);

      //         if (tokenRes) {
      //           let accessToken = tokenRes.accessToken.jwtToken;
      //           let sessionToken = tokenRes.idToken.jwtToken;
      //           let refreshToken = tokenRes.refreshToken.token;

      //           // set userData and token into Redux
      //           let uData = {
      //             token: {
      //               accessToken: accessToken,
      //               sessionToken: sessionToken,
      //               refreshToken: refreshToken
      //             },
      //             cognitoUser: {
      //               attributes: {
      //                 email: signInres.attributes.email,
      //                 sub: signInres.attributes.sub
      //               }
      //             },
      //             login: true
      //           }
      //           await this.store.dispatch(setAuthentication(uData));

      //           this.events.publish('loginn', {
      //             login: true,
      //           });
      //           this.getUserDetail(signInres);
      //         } else {
      //           this.loading = false;
      //         }
      //       }, (err) => {
      //         this.loading = false;
      //         console.log(err, "error");
      //       })
      //     } else {
      //       this.loading = false;
      //       this.unauthorize = true;
      //       // this.userNotfound = true;
      //     }
      //   }
      // })

    }
  }

  // getUserMeta(email) {
  //   let body = {
  //     id: email,
  //     filter: 'user#meta'
  //   }
  //   this.common.callApi(
  //     this.constant.USER_FILTER,
  //     body,
  //     'POST',
  //     '',
  //     false,
  //     false,
  //     true
  //   ).subscribe(async (results: any) => {
  //     console.log('User Meta -> ', results.user);
  //     this.loading = false;
  //     let userData = results.user[0]
  //     let uData = {
  //       email: userData.pk,
  //       cognitoID: userData.id,
  //       fullname: userData.fullname,
  //       userID: userData.id
  //     };

  //     await this.store.dispatch(setUser(uData));
  //     this.getUserAlert(uData, email)
  //     // this.modalCtrl.dismiss();


  //   }, err => {
  //     console.log('error in User Details - ', err);
  //   })
  // }

  // getUserAlert(userData, email) {
  //   let body = {
  //     id: email,
  //     filter: 'user#alert'
  //   }
  //   this.common.callApi(
  //     this.constant.USER_FILTER,
  //     body,
  //     'POST',
  //     '',
  //     false,
  //     false,
  //     true
  //   ).subscribe(async (results: any) => {
  //     console.log('User Alert -> ', results.user);
  //     this.loading = false;
  //     let data = results?.user[0];
  //     let len = results?.user?.length

  //     console.log(userData, data, len, "??????????")

  //     if (len > 0) {
  //       this.modalCtrl.dismiss()
  //       let uData = {
  //         email: userData.email,
  //         cognitoID: userData.userID,
  //         fullname: userData.fullname,
  //         userID: userData.userID,
  //         alert_preferences: data?.alert_preferences,
  //         mobile: data?.alert_preferences?.mobile == null ? "NA" : data?.mobile,
  //         country_code: data?.alert_preferences?.country_code
  //       };
  //       await this.store.dispatch(setUser(uData));
  //       this.router.navigate(['/tabs/jobsites']);
  //     } else {
  //       this.modalCtrl.dismiss()
  //       this.router.navigate(['/signup-preferences']);
  //     }
  //     // let uData = {
  //     //   email: userData.pk,
  //     //   cognitoID: userData.id,
  //     //   fullname: userData.fullname,
  //     //   userID: userData.id
  //     // };

  //     // await this.store.dispatch(setUser(uData));
  //     // this.modalCtrl.dismiss();

  //   }, err => {
  //     console.log('error in User Details - ', err);
  //   })
  // }

  getUserDetail(id) {
    console.log(id, "======");
    this.common.callApi(
      this.constant.USER_DETAIL_V2 + id,
      "",
      'GET',
      '',
      false,
      false,
      true
    ).subscribe(async (results: any) => {
      console.log('User Details -> ', results);
      // let userData = results.user
      // let uData = {
      //   email: '',
      //   cognitoID: '',
      //   fullname: '',
      //   userID: ''
      // };
      // userData.forEach(element => {
      //   if (element.sk = 'user#meta') {
      //     uData.email = element.pk,
      //       uData.cognitoID = element.id,
      //       uData.fullname = element.fullname,
      //       uData.userID = element.id
      //   }
      // });

      await this.store.dispatch(setUser(results));
      this.modalCtrl.dismiss();

      // userData.forEach(element => {
      //   console.log(element.sk, "=======")
      //   if (element.sk == 'user#alert') {
      //     this.router.navigate(['/tabs/jobsites']);
      //   }
      //   else {
      //     this.router.navigate(['/signup-preferences']);
      //   }
      // });

      if (results.hasOwnProperty("alert_preferences")) {
        // this.router.navigate(['/tabs/jobsites']);
        // this.router.navigate(['/signup-preferences']);
        this.router.navigate(['/signup-punchlist-email']);
      } else {
        this.router.navigate(['/signup-preferences']);
      }
    }, err => {
      console.log('error in User Details - ', err);
    })
  }

  async resendOtp() {
    // resendCode
    await this.authServices.resendCode(this.myForm.value.email).then(async (codeREs: any) => {
      console.log(codeREs, "--------");

      if (codeREs) {
        this.modalCtrl.dismiss();
        const modal = await this.modalCtrl.create({
          component: SignupPage,
          cssClass: 'modal_login',
          backdropDismiss: false,
          componentProps: {
            from: "login",
            username: this.myForm.value.email
          }
        });
        return await modal.present();
      }
    })
  }

  setFocus(nextElement) {
    console.log(nextElement)
    nextElement.setFocus();
  }

  setFocusButton() {
    document.getElementById('btn_verified1').click();
  }

  // setFocus1() {
  //   console.log("SET FOCUS")
  //   this.btn_verified1.setFocus()
  //   // this.btn_verified1.el.focus();
  // }

}
