import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-add-punchitem-same-location',
  templateUrl: './add-punchitem-same-location.page.html',
  styleUrls: ['./add-punchitem-same-location.page.scss'],
})
export class AddPunchitemSameLocationPage implements OnInit {

  punchItemData : any;
  locationTitle : any = "";
  constructor( private modalController: ModalController,
    private router: Router,
    private store: Store<any>) { }

  ngOnInit() {
  }
  
  ionViewWillEnter(){
    this.store.select<any>('punchItemData').subscribe(state => this.punchItemData = state);
    
    let locationData  = this.punchItemData.selectedPunchItemLocation
    if(locationData.unit != '' && locationData.street_address != ''){
      this.locationTitle = locationData.street_address;
    }else if(locationData.unit != '' && locationData.street_address == ''){
      this.locationTitle = locationData.unit + " " + locationData.development_site;
    }else if(locationData.unit == '' && locationData.street_address != ''){
      this.locationTitle = locationData.street_address;
    }


  
    console.log(this.locationTitle,"this.locationData")
  }

  yes() {
    this.modalController.dismiss('yes');
  }

  no() {
    this.modalController.dismiss('no');
  }

  goBack() {
    this.modalController.dismiss();
  }
}
