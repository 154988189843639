import { ActionTypes } from "../constants/action-types";
const initialState = {
    isChanged : false
};


export function roomReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.SET_ROOM_DATA:
            return { ...state, globalRooms: payload };
        case ActionTypes.SET_IS_ROOM_CHANGE:
            return { ...state, isChanged: payload };
        default:
            return state;
    }
}