import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { UtilService } from 'src/app/services/util/util.service';
import { LoginPage } from '../login/login.page';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage implements OnInit {

  myForm : UntypedFormGroup;
  passwordsForm : UntypedFormGroup;
  submitted = false;
  submitted1 = false;
  @ViewChild('inputEmail',{static : false}) inputEmail;
  loading = false;
  exist = false;
  userNotfound = false;
  afterEmail = false;
  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';
  confirm_passwordType: string = 'password';
  confirm_passwordIcon: string = 'eye-off';

  constructor(private router : Router,
    private formBuilder : UntypedFormBuilder,
    private modalCtrl : ModalController,
    private authServices : AuthenticationService,
    private util : UtilService) { }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]{1,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.][a-zA-Z]{1,4}) *$')]],
    })

    this.passwordsForm = this.formBuilder.group({
      new_password: ['', [Validators.required, Validators.minLength(4)]],
      confirm_password: ['', [Validators.required, Validators.minLength(4)]],
      code : ['', [Validators.required]]
    })
  }

  ionViewWillEnter(){
    setTimeout(() => {
      console.log("avyu")
      this.inputEmail.setFocus();
    },1000)
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  checkForHideShow(passwordType){
    if(passwordType == 'text'){
      return "Hide"
    }else{
      return "Show"
    }
  }

  hideShowConfirmPassword() {
    this.confirm_passwordType = this.confirm_passwordType === 'text' ? 'password' : 'text';
    this.confirm_passwordIcon = this.confirm_passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  emailFocus() {
    this.exist = false;
  }

  passwordFocus(){}
  confirm_passwordFocus(){}
  blurPassword(){}
  confirm_blurPassword(){}
  codeFocus(){}
  codeBlur(){}

  goBack(){
    this.modalCtrl.dismiss();
  }

  get errorCtr() {
    return this.myForm.controls;
  }

  get errorCtr1() {
    return this.passwordsForm.controls;
  }

  async onSubmit() {
    this.submitted = true;
    
    if (!this.myForm.valid) {
      console.log('All fields are required.');
      this.afterEmail = false;
      return false;
    } else {
      this.loading = true;
      await this.authServices.sendForgotPasswordCode(this.myForm.value.email).then(async (codeRes:any) => {
        console.log(codeRes,"--------");
        this.afterEmail = true;
        this.loading = false;
      })
    }
  }

  async onSubmitFinal() {
    this.submitted1 = true;
    
    if (!this.passwordsForm.valid) {
      console.log('All fields are required.');
      return false;
    } else {
      this.loading = true;
      if (this.passwordsForm.value.new_password == this.passwordsForm.value.confirm_password) {
        this.authServices.forgotPassword(this.myForm.value.email, this.passwordsForm.value.code, this.passwordsForm.value.new_password)
          .then(data => {
            console.log(data,"datata");
            if(data == 'CodeMismatchException'){
              this.loading = false;
              this.util.presentToast("Invalid verification code provided, please try again")
            }else{
              this.loading = false;
              this.login();
              this.util.presentToast("Password Updated Successfully.")
            }
          })
      } else {
        this.loading = false;
        this.util.presentToast("Passwords does not match.")
      }

      // this.loading = true;
      // this.api.POST_API("/public/checkEmail",this.myForm.controls.email.value).subscribe((emailData : any) => {
      //   console.log("Email verify",emailData);
      //   this.loading = false;
      //   if(emailData.status == 200){
      //     this.exist = false;
      //     this.confirmLink()
      //   }else{
      //     this.exist = true;
      //   }
      // },(err) => {
      //   this.loading = false;
      //   console.log("Error in verify Email",err)
      // })
    }
  }

  async login() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: LoginPage,
      cssClass: 'modal_login',
      backdropDismiss: false
    });
    return await modal.present();
  }

  async confirmLink(){
    this.modalCtrl.dismiss();
  }

}
