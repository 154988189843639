import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  // { path: '', redirectTo: '/tabs/jobsites', pathMatch: 'full'},
  { path: '', redirectTo: '/routing', pathMatch: 'full' },
  // {
  //   path: 'biometric-authentication',
  //   loadChildren: () => import('./pages/biometric-authentication/biometric-authentication.module').then( m => m.BiometricAuthenticationPageModule)
  // },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'how-does-it-work',
    loadChildren: () => import('./pages/how-does-it-work/how-does-it-work.module').then(m => m.HowDoesItWorkPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqPageModule)
  },
  {
    path: 'test-drive',
    loadChildren: () => import('./pages/test-drive/test-drive.module').then(m => m.TestDrivePageModule),
  },
  {
    path: 'signup-preferences',
    loadChildren: () => import('./pages/signup-preferences/signup-preferences.module').then(m => m.SignupPreferencesPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'signup-describe',
    loadChildren: () => import('./pages/signup-describe/signup-describe.module').then(m => m.SignupDescribePageModule)
  },
  {
    path: 'signup-punchlist-email',
    loadChildren: () => import('./pages/signup-punchlist-email/signup-punchlist-email.module').then(m => m.SignupPunchlistEmailPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'advance-alert-preferences',
    loadChildren: () => import('./pages/advance-alert-preferences/advance-alert-preferences.module').then(m => m.AdvanceAlertPreferencesPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'testdrive-email',
    loadChildren: () => import('./pages/testdrive-email/testdrive-email.module').then(m => m.TestdriveEmailPageModule)
  },
  {
    path: 'testdrive-fullname',
    loadChildren: () => import('./pages/testdrive-fullname/testdrive-fullname.module').then(m => m.TestdriveFullnamePageModule)
  },
  {
    path: 'map-location',
    loadChildren: () => import('./pages/map-location/map-location.module').then(m => m.MapLocationPageModule)
  },
  {
    path: 'punch-list-items',
    loadChildren: () => import('./pages/punch-list-items/punch-list-items.module').then(m => m.PunchListItemsPageModule)
  },
  {
    path: 'trade-modal',
    loadChildren: () => import('./modals/trade-modal/trade-modal.module').then(m => m.TradeModalPageModule)
  },
  {
    path: 'punch-list-modal',
    loadChildren: () => import('./modals/punch-list-modal/punch-list-modal.module').then(m => m.PunchListModalPageModule)
  },
  {
    path: 'jobsite-status',
    loadChildren: () => import('./modals/jobsite-status/jobsite-status.module').then(m => m.JobsiteStatusPageModule)
  },
  {
    path: 'jobsite-detail-list',
    loadChildren: () => import('./pages/jobsite-detail-list/jobsite-detail-list.module').then(m => m.JobsiteDetailListPageModule)
  },
  {
    path: 'search-location',
    loadChildren: () => import('./pages/search-location/search-location.module').then(m => m.SearchLocationPageModule)
  },
  {
    path: 'punchdraft-detail',
    loadChildren: () => import('./pages/punchdraft-detail/punchdraft-detail.module').then(m => m.PunchdraftDetailPageModule)
  },
  {
    path: 'advance-alert-mobilenumber',
    loadChildren: () => import('./modals/advance-alert-mobilenumber/advance-alert-mobilenumber.module').then(m => m.AdvanceAlertMobilenumberPageModule)
  },
  {
    path: 'image-viewer',
    loadChildren: () => import('./modals/image-viewer/image-viewer.module').then(m => m.ImageViewerPageModule)
  },
  {
    path: 'active-punch-warning',
    loadChildren: () => import('./modals/active-punch-warning/active-punch-warning.module').then(m => m.ActivePunchWarningPageModule)
  },
  {
    path: 'go-draft',
    loadChildren: () => import('./modals/go-draft/go-draft.module').then(m => m.GoDraftPageModule)
  },
  {
    path: 'help-text',
    loadChildren: () => import('./modals/help-text/help-text.module').then(m => m.HelpTextPageModule)
  },
  {
    path: 'contact-permission',
    loadChildren: () => import('./modals/contact-permission/contact-permission.module').then(m => m.ContactPermissionPageModule)
  },
  {
    path: 'add-contact',
    loadChildren: () => import('./pages/add-contact/add-contact.module').then(m => m.AddContactPageModule)
  },
  {
    path: 'add-date',
    loadChildren: () => import('./pages/add-date/add-date.module').then(m => m.AddDatePageModule)
  },
  {
    path: 'add-notes',
    loadChildren: () => import('./pages/add-notes/add-notes.module').then(m => m.AddNotesPageModule)
  },
  {
    path: 'jobsite-detail-occupied',
    loadChildren: () => import('./pages/jobsite-detail-occupied/jobsite-detail-occupied.module').then(m => m.JobsiteDetailOccupiedPageModule)
  },
  {
    path: 'contact-selected',
    loadChildren: () => import('./pages/contact-selected/contact-selected.module').then(m => m.ContactSelectedPageModule)
  },
  {
    path: 'invite-preferences',
    loadChildren: () => import('./pages/invite-preferences/invite-preferences.module').then(m => m.InvitePreferencesPageModule)
  },
  {
    path: 'invite-register',
    loadChildren: () => import('./pages/invite-register/invite-register.module').then(m => m.InviteRegisterPageModule)
  },
  {
    path: 'phone-email-selection',
    loadChildren: () => import('./modals/phone-email-selection/phone-email-selection.module').then(m => m.PhoneEmailSelectionPageModule)
  },
  {
    path: 'edit-contact',
    loadChildren: () => import('./pages/edit-contact/edit-contact.module').then(m => m.EditContactPageModule)
  },
  {
    path: 'edit-occupant-contact',
    loadChildren: () => import('./pages/edit-occupant-contact/edit-occupant-contact.module').then(m => m.EditOccupantContactPageModule)
  },
  {
    path: 'search-contact',
    loadChildren: () => import('./pages/search-contact/search-contact.module').then(m => m.SearchContactPageModule)
  },
  {
    path: 'wrong-number-select',
    loadChildren: () => import('./modals/wrong-number-select/wrong-number-select.module').then(m => m.WrongNumberSelectPageModule)
  },
  {
    path: 'target-date-help',
    loadChildren: () => import('./modals/target-date-help/target-date-help.module').then(m => m.TargetDateHelpPageModule)
  },
  {
    path: 'verification-code',
    loadChildren: () => import('./pages/verification-code/verification-code.module').then(m => m.VerificationCodePageModule)
  },
  {
    path: 'routing',
    loadChildren: () => import('./pages/routing/routing.module').then(m => m.RoutingPageModule)
  },
  {
    path: 'image-editor',
    loadChildren: () => import('./modals/image-editor/image-editor.module').then(m => m.ImageEditorPageModule)
  },
  {
    path: 'add-trade-contact',
    loadChildren: () => import('./pages/add-trade-contact/add-trade-contact.module').then(m => m.AddTradeContactPageModule)
  },
  {
    path: 'trade-contact-help',
    loadChildren: () => import('./modals/trade-contact-help/trade-contact-help.module').then(m => m.TradeContactHelpPageModule)
  },
  {
    path: 'trade-contact-list',
    loadChildren: () => import('./pages/trade-contact-list/trade-contact-list.module').then(m => m.TradeContactListPageModule)
  },
  {
    path: 'trade-confirmed',
    loadChildren: () => import('./modals/trade-confirmed/trade-confirmed.module').then(m => m.TradeConfirmedPageModule)
  },
  {
    path: 'trade-summary',
    loadChildren: () => import('./pages/trade-summary/trade-summary.module').then(m => m.TradeSummaryPageModule)
  },
  {
    path: 'trade-date-time',
    loadChildren: () => import('./modals/trade-date-time/trade-date-time.module').then(m => m.TradeDateTimePageModule)
  },
  {
    path: 'primary-contact',
    loadChildren: () => import('./modals/primary-contact/primary-contact.module').then(m => m.PrimaryContactPageModule)
  },
  {
    path: 'trade-contact-more-info',
    loadChildren: () => import('./modals/trade-contact-more-info/trade-contact-more-info.module').then(m => m.TradeContactMoreInfoPageModule)
  },
  {
    path: 'due-date-content',
    loadChildren: () => import('./modals/due-date-content/due-date-content.module').then(m => m.DueDateContentPageModule)
  },
  {
    path: 'add-due-date',
    loadChildren: () => import('./pages/add-due-date/add-due-date.module').then(m => m.AddDueDatePageModule)
  },
  {
    path: 'great-job-punch-list',
    loadChildren: () => import('./modals/great-job-punch-list/great-job-punch-list.module').then(m => m.GreatJobPunchListPageModule)
  },
  {
    path: 'great-job-content',
    loadChildren: () => import('./modals/great-job-content/great-job-content.module').then(m => m.GreatJobContentPageModule)
  },
  {
    path: 'contact-list',
    loadChildren: () => import('./pages/contact-list/contact-list.module').then(m => m.ContactListPageModule)
  },
  {
    path: 'contact-details',
    loadChildren: () => import('./pages/contact-details/contact-details.module').then(m => m.ContactDetailsPageModule)
  },
  {
    path: 'import-contacts',
    loadChildren: () => import('./pages/import-contacts/import-contacts.module').then(m => m.ImportContactsPageModule)
  },
  {
    path: 'imported-contact-modal',
    loadChildren: () => import('./modals/imported-contact-modal/imported-contact-modal.module').then(m => m.ImportedContactModalPageModule)
  },
  {
    // path: 'active-punch-details',
    path: 'active-punch-details/:locationID',
    loadChildren: () => import('./pages/active-punch-details/active-punch-details.module').then(m => m.ActivePunchDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'active-punch-item-details/:locationID/:punchItemId/:deepLink',
    loadChildren: () => import('./pages/active-punch-item-details/active-punch-item-details.module').then(m => m.ActivePunchItemDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'assign-punch-item',
    loadChildren: () => import('./modals/assign-punch-item/assign-punch-item.module').then(m => m.AssignPunchItemPageModule)
  },
  {
    path: 'active-punch-statuses',
    loadChildren: () => import('./modals/active-punch-statuses/active-punch-statuses.module').then(m => m.ActivePunchStatusesPageModule)
  },
  {
    path: 'active-punch-materials',
    loadChildren: () => import('./modals/active-punch-materials/active-punch-materials.module').then(m => m.ActivePunchMaterialsPageModule)
  },
  {
    path: 'punch-item-alert',
    loadChildren: () => import('./modals/punch-item-alert/punch-item-alert.module').then(m => m.PunchItemAlertPageModule)
  },
  {
    path: 'punch-item-comment',
    loadChildren: () => import('./pages/punch-item-comment/punch-item-comment.module').then(m => m.PunchItemCommentPageModule)
  },
  {
    path: 'active-punch-tags',
    loadChildren: () => import('./modals/active-punch-tags/active-punch-tags.module').then(m => m.ActivePunchTagsPageModule)
  },
  {
    path: 'resend-punch-modal',
    loadChildren: () => import('./modals/resend-punch-modal/resend-punch-modal.module').then(m => m.ResendPunchModalPageModule)
  },
  {
    path: 'active-punch-trade-contact',
    loadChildren: () => import('./modals/active-punch-trade-contact/active-punch-trade-contact.module').then(m => m.ActivePunchTradeContactPageModule)
  },
  {
    path: 'punch-assign-me',
    loadChildren: () => import('./modals/punch-assign-me/punch-assign-me.module').then(m => m.PunchAssignMePageModule)
  },
  {
    // path: 'active-punch-notes-contacts',
    path: 'active-punch-notes-contacts/:locationID',
    loadChildren: () => import('./pages/active-punch-notes-contacts/active-punch-notes-contacts.module').then(m => m.ActivePunchNotesContactsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'activity-filter-type',
    loadChildren: () => import('./pages/activity-filter-type/activity-filter-type.module').then(m => m.ActivityFilterTypePageModule)
  },
  {
    path: 'active-punch-filters',
    loadChildren: () => import('./pages/active-punch-filters/active-punch-filters.module').then(m => m.ActivePunchFiltersPageModule)
  },
  {
    path: 'active-jobsite-sort',
    loadChildren: () => import('./pages/active-jobsite-sort/active-jobsite-sort.module').then( m => m.ActiveJobsiteSortPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'new-message',
    loadChildren: () => import('./modals/new-message/new-message.module').then( m => m.NewMessagePageModule)
  },
  {
    path: 'track-progress',
    loadChildren: () => import('./modals/track-progress/track-progress.module').then( m => m.TrackProgressPageModule)
  },
  {
    path: 'get-home',
    loadChildren: () => import('./modals/get-home/get-home.module').then( m => m.GetHomePageModule)
  },
  {
    path: 'jobsite-sort-modal',
    loadChildren: () => import('./modals/jobsite-sort-modal/jobsite-sort-modal.module').then( m => m.JobsiteSortModalPageModule)
  },
  {
    path: 'jobsite-update-modal',
    loadChildren: () => import('./modals/jobsite-update-modal/jobsite-update-modal.module').then( m => m.JobsiteUpdateModalPageModule)
  },
  {
    path: 'jobsite-find-detail',
    loadChildren: () => import('./modals/jobsite-find-detail/jobsite-find-detail.module').then( m => m.JobsiteFindDetailPageModule)
  },
  {
    path: 'create-list-alert',
    loadChildren: () => import('./modals/create-list-alert/create-list-alert.module').then( m => m.CreateListAlertPageModule)
  },
  {
    path: 'assign-list-alert',
    loadChildren: () => import('./modals/assign-list-alert/assign-list-alert.module').then( m => m.AssignListAlertPageModule)
  },
  {
    path: 'assign-team-member-alert',
    loadChildren: () => import('./modals/assign-team-member-alert/assign-team-member-alert.module').then( m => m.AssignTeamMemberAlertPageModule)
  },
  {
    path: 'add-contact-alert',
    loadChildren: () => import('./modals/add-contact-alert/add-contact-alert.module').then( m => m.AddContactAlertPageModule)
  },
  {
    path: 'chat-alert',
    loadChildren: () => import('./modals/chat-alert/chat-alert.module').then( m => m.ChatAlertPageModule)
  },
  {
    path: 'punchlist-create-alert',
    loadChildren: () => import('./modals/punchlist-create-alert/punchlist-create-alert.module').then( m => m.PunchlistCreateAlertPageModule)
  },
  {
    path: 'punchlist-action-alert',
    loadChildren: () => import('./modals/punchlist-action-alert/punchlist-action-alert.module').then( m => m.PunchlistActionAlertPageModule)
  },
  {
    path: 'see-item-alert',
    loadChildren: () => import('./modals/see-item-alert/see-item-alert.module').then( m => m.SeeItemAlertPageModule)
  },
  {
    path: 'punchlist-help-alert',
    loadChildren: () => import('./modals/punchlist-help-alert/punchlist-help-alert.module').then( m => m.PunchlistHelpAlertPageModule)
  },
  {
    path: 'punchlist-status-alert',
    loadChildren: () => import('./modals/punchlist-status-alert/punchlist-status-alert.module').then( m => m.PunchlistStatusAlertPageModule)
  },
  {
    path: 'punchlist-print-alert',
    loadChildren: () => import('./modals/punchlist-print-alert/punchlist-print-alert.module').then( m => m.PunchlistPrintAlertPageModule)
  },
  {
    path: 'punchlist-handoff-alert',
    loadChildren: () => import('./modals/punchlist-handoff-alert/punchlist-handoff-alert.module').then( m => m.PunchlistHandoffAlertPageModule)
  },
  {
    path: 'punchlist-work-alert',
    loadChildren: () => import('./modals/punchlist-work-alert/punchlist-work-alert.module').then( m => m.PunchlistWorkAlertPageModule)
  },
  {
    path: 'add-punchitem-same-location',
    loadChildren: () => import('./modals/add-punchitem-same-location/add-punchitem-same-location.module').then( m => m.AddPunchitemSameLocationPageModule)
  },
  {
    path: 'add-plan',
    loadChildren: () => import('./pages/add-plan/add-plan.module').then( m => m.AddPlanPageModule)
  },
  {
    path: 'plan-modal',
    loadChildren: () => import('./modals/plan-modal/plan-modal.module').then( m => m.PlanModalPageModule)
  },
  {
    path: 'plan-status',
    loadChildren: () => import('./modals/plan-status/plan-status.module').then( m => m.PlanStatusPageModule)
  },
  {
    path: 'plan-list',
    loadChildren: () => import('./pages/plan-list/plan-list.module').then( m => m.PlanListPageModule)
  },
  {
    path: 'mistake-modal',
    loadChildren: () => import('./modals/mistake-modal/mistake-modal.module').then( m => m.MistakeModalPageModule)
  },
  {
    path: 'location-exist',
    loadChildren: () => import('./modals/location-exist/location-exist.module').then( m => m.LocationExistPageModule)
  },
  {
    path: 'date-modal',
    loadChildren: () => import('./pages/date-modal/date-modal.module').then( m => m.DateModalPageModule)
  },
  {
    path: 'plan-completion',
    loadChildren: () => import('./modals/plan-completion/plan-completion.module').then( m => m.PlanCompletionPageModule)
  },
  {
    path: 'resend-massage-modal',
    loadChildren: () => import('./modals/resend-massage-modal/resend-massage-modal.module').then( m => m.ResendMassageModalPageModule)
  },
  {
    path: 'add-trade-contact-modal',
    loadChildren: () => import('./modals/add-trade-contact-modal/add-trade-contact-modal.module').then( m => m.AddTradeContactModalPageModule)
  },
  {
    path: 'active-punch-statuses-multiple',
    loadChildren: () => import('./modals/active-punch-statuses-multiple/active-punch-statuses-multiple.module').then( m => m.ActivePunchStatusesMultiplePageModule)
  },
  {
    path: 'disagree-note',
    loadChildren: () => import('./modals/disagree-note/disagree-note.module').then( m => m.DisagreeNotePageModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/team/team.module').then( m => m.TeamPageModule)
  },
  {
    path: 'assign-punch-multile',
    loadChildren: () => import('./modals/assign-punch-multile/assign-punch-multile.module').then( m => m.AssignPunchMultilePageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
