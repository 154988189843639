import { Injectable } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

Amplify.configure({
  Auth: {
    region: environment.region,
    userPoolId: environment.userPoolId,
    identityPoolId: environment.identityPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
  },
  Storage: {
    AWSS3: {
      bucket: 'punchitemsimages132825-dev', //REQUIRED -  Amazon S3 bucket name
      region: environment.region
    }
  }
})
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor() { }

  async signIn(email, password) {
    return await Auth.signIn(email, password).then((response) => {
      console.log("Login response: ", response);
      if (response) {
        const res =
          (response) ||
          {};
        return res;
      }
      return false;
    })
      .catch((err) => {
        console.log(err);
        return err.name;
      }
      );
  }

  async GetAuthenticationToken() {
    return await Auth.currentSession().then((response) => {
      console.log(" Token response: ", response);
      if (response) {
        const res =
          (response) ||
          {};
        return res;
      }
      return false;
    })
      .catch((err) => {
        console.log(err);
        return err.name;
      }
      );
  }

  async signUp(data) {
    return await Auth.signUp(data).then((response) => {
      console.log("Register response: ", response);
      if (response) {
        const user =
          (response) ||
          {};
        return user;
      }
      return false;
    })
      .catch((err) => {
        console.log(err.message);
        return err.name;
      });
  }

  async verifyCode(email, code) {
    return await Auth.confirmSignUp(email, code).then((response) => {
      console.log("verification of code response: ", response);
      if (response) {
        const user =
          (response) ||
          {};
        return user;
      }
      return false;
    })
      .catch((err) => {
        console.log(err.message);
        return err.name;
      });
  }

  async resendCode(email) {
    console.log(email)
    return await Auth.resendSignUp(email, { "metadataKey1": "metadataValue1" }).then((response) => {
      console.log("resend of code response: ", response);
      if (response) {
        const user =
          (response) || {};
        return user;
      }
      return false;
    })
      .catch((err) => {
        console.log(err.message);
        return err.name;
      });
  }

  async sendForgotPasswordCode(email) {
    return await Auth.forgotPassword(email).then((response) => {
      console.log("send code of forgot password response: ", response);
      if (response) {
        const user =
          (response) || {};
        return user;
      }
      return false;
    })
      .catch((err) => {
        console.log(err.message);
        return err.name;
      });
  }

  async forgotPassword(userName, code, password) {
    return await Auth.forgotPasswordSubmit(userName, code, password).then((response) => {
      console.log("forgot password response: ", response);
      if (response) {
        const user =
          (response) || {};
        return user;
      }
      return false;
    })
      .catch((err) => {
        console.log(err.message);
        return err.name;
      });
  }

}
