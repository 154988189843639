export const ActionTypes = {
  SET_AUTH: "SET_AUTH",
  SET_USER : "SET_USER",
  SET_NAVIGATION_PARAMETER : "SET_NAVIGATION_PARAMETER",
  SET_ALL_USER_LOCATION : "SET_ALL_USER_LOCATION",
  SET_LOCATION : "SET_LOCATION",
  SET_SELECTED_LOCATION : "SET_SELECTED_LOCATION",
  SET_LOCATION_ID : "SET_LOCATION_ID",
  SET_ALREADY_HAVE_LOCATION : "SET_ALREADY_HAVE_LOCATION",
  SET_ADDRESS_TYPE : "SET_ADDRESS_TYPE",
  SET_SELECTED_TRADE: "SET_SELECTED_TRADE",
  SET_CURRENT_TRADE_INDEX: "SET_CURRENT_TRADE_INDEX",
  SET_PUNCH_ITEM_DETAIL: "SET_PUNCH_ITEM_DETAIL",
  SET_PUNCH_ITEM_LOCATION: "SET_PUNCH_ITEM_LOCATION",
  SET_ACTIVITY_FILTER : "SET_ACTIVITY_FILTER",
  SET_JOBSITE_FILTER : "SET_JOBSITE_FILTER", 
  SET_TRADE_DATA: "SET_TRADE_DATA",
  SET_IS_TRADE_CHANGE: "SET_IS_TRADE_CHANGE",
  SET_ROOM_DATA: "SET_ROOM_DATA",
  SET_IS_ROOM_CHANGE: "SET_IS_ROOM_CHANGE",
  SET_SELECTED_LOCATION_PUNCH_ITEMS:"SET_SELECTED_LOCATION_PUNCH_ITEMS",
  SET_ACTIVE_LOCATIONS: "SET_ACTIVE_LOCATIONS",
  SET_ACTIVE_LOCATIONS_LASTUPDATE: "SET_ACTIVE_LOCATIONS_LASTUPDATE",
  SET_DESCRIPTION_DATA: "SET_DESCRIPTION_DATA",
  SET_DEVELOPMENT_DATA: "SET_DEVELOPMENT_DATA",
  SET_EXPAND_ROOMS: "SET_EXPAND_ROOMS",
  SET_TREE_DATA: "SET_TREE_DATA",
  SET_EXPAND_TRADES: "SET_EXPAND_TRADES",
  SET_LOATION_SORTING: "SET_LOATION_SORTING",
  SET_VISITED_LOCATION: "SET_VISITED_LOCATION",
  SET_PUNCH_ITEMS_COMMENTS: "SET_PUNCH_ITEMS_COMMENTS",
  SET_PUNCH_ITEMS_STORE: "SET_PUNCH_ITEMS_STORE",
  SET_USER_LOCAL_DATA_UPDATE: "SET_USER_LOCAL_DATA_UPDATE",
  SET_PLAN_DETAIL: "SET_PLAN_DETAIL",
  SET_EXPAND_PLANS: "SET_EXPAND_PLANS",
  LAST_CONTACT_FETCH: "LAST_CONTACT_FETCH",
  PREVIOUSLY_USED_CONTACTS: "PREVIOUSLY_USED_CONTACTS",
  SET_LOCATION_JOBSITE_CONTACTS: "SET_LOCATION_JOBSITE_CONTACTS",
  SET_LOCATION_IMPORTANT_DATES: "SET_LOCATION_IMPORTANT_DATES",
  SET_ALERTS_LOCATION: "SET_ALERTS_LOCATION",
  SET_SCHEDULED_LOCATION: "SET_SCHEDULED_LOCATION",
  SET_ALERTS_ACTIVE_LOCATIONS_LASTUPDATE: "SET_ALERTS_ACTIVE_LOCATIONS_LASTUPDATE"
};
