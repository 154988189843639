import { ActionTypes } from "../constants/action-types";
const initialState = {
    
};


export function developmentReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.SET_DEVELOPMENT_DATA:
            return { ...state, globalDevelopment: payload };
        default:
            return state;
    }
}