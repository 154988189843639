import { ActionTypes } from "../constants/action-types";

export const setAllUserLocation = (val) => {
  return {
    type: ActionTypes.SET_ALL_USER_LOCATION,
    payload: val,
  };
};

export const setLocation = (val) => {
  return {
    type: ActionTypes.SET_LOCATION,
    payload: val,
  };
};

export const setSelectedLocation = (val) => {
  return {
    type: ActionTypes.SET_SELECTED_LOCATION,
    payload: val,
  };
};

export const setLocationID = (val) => {
  return {
    type: ActionTypes.SET_LOCATION_ID,
    payload: val,
  };
};

export const setAlredyHaveLocation = (val) => {
  return {
    type: ActionTypes.SET_ALREADY_HAVE_LOCATION,
    payload: val,
  };
};

export const setAddressType = (val) => {
  return {
    type: ActionTypes.SET_ADDRESS_TYPE,
    payload: val,
  };
};

export const setActiveLocations = (val) => {
  return {
    type: ActionTypes.SET_ACTIVE_LOCATIONS,
    payload: val,
  };
};

export const setActiveLocationsLastupdate = (val) => {
  return {
    type: ActionTypes.SET_ACTIVE_LOCATIONS_LASTUPDATE,
    payload: val,
  };
};

export const setLocationSorting = (val) => {
  return {
    type: ActionTypes.SET_LOATION_SORTING,
    payload: val,
  };
};

export const setVisitedLocation = (val) => {
  return {
    type: ActionTypes.SET_VISITED_LOCATION,
    payload: val,
  };
};

export const setUserLocalDataUpdate = (val) => {
  return {
    type: ActionTypes.SET_USER_LOCAL_DATA_UPDATE,
    payload: val,
  };
};

export const setLastContactFetch = (val) => {
  return {
    type: ActionTypes.LAST_CONTACT_FETCH,
    payload: val,
  };
};

export const setPreviouslyUsedContacts = (val) => {
  return {
    type: ActionTypes.PREVIOUSLY_USED_CONTACTS,
    payload: val,
  };
};

export const setLocationJobsiteContacts = (val) => {
  return {
    type: ActionTypes.SET_LOCATION_JOBSITE_CONTACTS,
    payload: val,
  };
};

export const setLocationaImportantDates = (val) => {
  return {
    type: ActionTypes.SET_LOCATION_IMPORTANT_DATES,
    payload: val,
  };
};