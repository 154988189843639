import { ActionTypes } from "../constants/action-types";
const initialState = {};

export function planDetailReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.SET_PLAN_DETAIL:
            return { ...state, planDetail: payload };
        default:
            return state;
    }
}