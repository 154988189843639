import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { LoginPage } from '../login/login.page';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { UtilService } from 'src/app/services/util/util.service';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'src/app/services/auth/authentication.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ConstantService } from 'src/app/services/constant/constant.service';
import { result } from 'lodash';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})

export class SignupPage implements OnInit {
  myForm: UntypedFormGroup;
  submitted = false;
  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';
  loading = false;
  alreadyExist = false;
  passwordNotCorrect = false;
  emailFocusValue = false;
  emailVerified = false;
  user: any;
  @Input() fromPage: string;
  alertpreFlag = false;
  user_id: any;
  nameFeild = false;
  emailFeild = false;
  companyFeild = false;
  flagCode = false;
  verificationCodeForCount: any;
  verificationCode: any;
  currentUserSub: any;
  @Input() from: string;
  @Input() username: string;
  wrongCode = false;
  verificationSuccess = false
  hasNumber = false;
  has6Digit = false;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    inputStyles: {

    }
  }

  @ViewChild('inputEmail', { static: false }) inputEmail;
  constructor(private navCtrl: NavController,
    private router: Router,
    private modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    private common: CommonService,
    private constant: ConstantService,
    private api: ApiService,
    private util: UtilService,
    private authServices: AuthenticationService,
    private route: ActivatedRoute,
    private store: Store
  ) { }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      company: [''],
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]{1,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.][a-zA-Z]{1,4}) *$')]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-zA-Z])(?=.*[0-9]).{6,}')]]
    })

    if (this.from == 'login') {
      this.flagCode = true;
      this.myForm.controls.email.setValue(this.username);
    }
  }

  ionViewWillEnter() {
    // this.api.setSplitPane(false);
    if (localStorage.getItem("emailFromLogin") != null) {
      let email = localStorage.getItem("emailFromLogin");
      this.myForm.controls.email.setValue(email);
    }
    setTimeout(() => {
      console.log("avyu")
      this.inputEmail.setFocus();
    }, 1000)
  }

  fullnameFocus() {
    this.nameFeild = true;
  }

  fullnameBlur() {
    this.nameFeild = false;
  }

  emailFocus() {
    this.emailFocusValue = true;
    this.alreadyExist = false;
    this.passwordNotCorrect = false;
    this.emailVerified = false;
    this.emailFeild = true;
  }

  emailBlur() {
    this.emailFeild = false;
  }

  companyFocus() {
    this.companyFeild = true;
  }

  companyBlur(){
    this.companyFeild = false;
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  checkForHideShow(passwordType){
    if(passwordType == 'text'){
      return "Hide"
    }else{
      return "Show"
    }
  }

  onOtpChange(value) {
    this.verificationCodeForCount = value;
    if (this.verificationCodeForCount.length == 6) {
      this.verificationCode = this.verificationCodeForCount;
    }
  }

  submit() {
    if (this.verificationCodeForCount.length == 6) {
      this.checkVerification()
    }
  }

  goBack() {
    localStorage.removeItem("emailFromLogin");
    this.modalCtrl.dismiss();
  }

  nameChange(event) {
    this.myForm.controls.name.setValue(this.capitalizeName(event.detail.value));
  }

  capitalizeName(name) {
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
  }

  async login() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: LoginPage,
      cssClass: 'modal_login',
      backdropDismiss: false
    });
    return await modal.present();
  }

  get errorCtr() {
    return this.myForm.controls;
  }

  async onSubmit() {
    this.submitted = true;
    if (!this.myForm.valid) {
      console.log('All fields are required.')
      this.emailFocusValue = false;
      this.emailVerified = false;
      return false;
    } else {
      this.loading = true;
      let dataMobile = {
        type: "email",
        value: this.myForm.controls.email.value
      }
      this.common.callApi(
        this.constant.CHECK_USER_REGISTERED_V2,
        dataMobile,
        'POST',
        '',
        false,
        false,
        true
      ).subscribe(async (results: any) => {
        console.log("checkDataMobile === ", results);
        if (results.message == "User Exist") {
          this.loading = false
          this.alreadyExist = true;
        }else if(results.status == 206){
          this.loading = false
          // Create cognito user
          this.user_id = results.id;
          this.registerUser('true');
        }
      }, (err) => {
        // this.loading = false;
        console.log("error in checkDataMobile", err.error.message);
        if (err.error.message = "User Not Exist") {
          this.registerUser('false');
        }
      })
    }
  }

  registerUser(isPartial) {
    this.alreadyExist = false;
    let data = {
      email: this.myForm.value.email,
      password: this.myForm.value.password
    }
    // Api call for signup
    // this.loading = true;
    this.common.callApi(
      this.constant.REGISTER_V2,
      data,
      'POST',
      '',
      false,
      false,
      false
    ).subscribe(async (results: any) => {
      console.log("Register === ", results);
      if (results?.user?.hasOwnProperty("userSub")) {
        this.loading = false;
        this.verificationCode = undefined;
        this.flagCode = true;
        this.passwordNotCorrect = false;
        this.alreadyExist = false;

        if(isPartial == 'true'){
         this.updateUser(results?.user?.userSub);
         this.ceateUserSubscriptionStatus(this.user_id)
        }else{
          this.addUserToDatabase(results?.user?.userSub);
        }

      }

    }, (err) => {
      this.loading = false;
      console.log("error in checkDataMobile === ", err,err?.error?.error?.name);
      if(err?.error?.error?.name == 'UsernameExistsException'){
        this.alreadyExist = true;
      }
    })

    // await this.authServices.signUp(data).then(async (signUpres: any) => {
    //   console.log(signUpres, "--------");

    //   if (signUpres != 'InvalidPasswordException' && signUpres != 'UsernameExistsException') {
    //     this.loading = false;
    //     this.verificationCode = undefined;
    //     this.flagCode = true;
    //     this.passwordNotCorrect = false;
    //     this.alreadyExist = false;

    //     this.currentUserSub = signUpres.userSub;
    //     this.addFullName(signUpres.userSub);
    //   } else if (signUpres == 'InvalidPasswordException') {
    //     this.loading = false;
    //     this.passwordNotCorrect = true;
    //   } else if (signUpres == 'UsernameExistsException') {
    //     this.loading = false;
    //     this.alreadyExist = true;
    //   }
    // })
  }

  updateUser(userSub){
    let data = {
      id: this.user_id,
      sk: "user#meta",
      updateBody: {
        GSI1PK: "user",
        GSI1SK: userSub,
        fullname: this.myForm.value.name,
        type: "main"
      }
    }

    console.log(data, "data user Update")

    this.common.callApi(
      this.constant.USER_UPDATE_FILTER,
      data,
      'PUT',
      '',
      false,
      false,
      true
    ).subscribe(async (results: any) => {
      console.log('Updated User Details -> ', results);
      this.loading = false;
    }, err => {
      this.loading = false;
      console.log('error in Update User Details - ', err);
    })
  }

  addUserToDatabase(username) {
    let param = {
      id: username,
      sk: 'user#meta',
      fullname: this.myForm.value.name,
      email: this.myForm.value.email,
      company: this.myForm.value.company,
    }
    console.log(param, "parameter");
    this.common.callApi(
      this.constant.CREATE_USER_V2,
      param,
      'POST',
      '',
      false,
      false,
      false
    ).subscribe((results: any) => {
      console.log('Added User -> ', results);
      localStorage.removeItem("emailFromLogin");
      this.ceateUserSubscriptionStatus(results.user?.id)
    }, err => {
      console.log('error in Ading User - ', err);
    })
  }

  ceateUserSubscriptionStatus(username) {
    let param = {
      id: username,
      sk: 'user#subscription#'+Date.now().toString(),
      sub_status: 'Free Trial'
    }
    console.log(param, "parameter");
    this.common.callApi(
      this.constant.CREATE_USER_SUBSCRIPTION,
      param,
      'POST',
      '',
      false,
      false,
      false
    ).subscribe((results: any) => {
      console.log('user subscription created -> ', results);
      localStorage.removeItem("emailFromLogin");
    }, err => {
      console.log('error in Ading User - ', err);
    })
  }


  // addFullName(userSub) {
  //   let param = {
  //     sub: userSub,
  //     fullname: this.myForm.value.name,
  //     email: this.myForm.value.email
  //   }

  //   console.log(param, "parameter");
  //   this.common.callApi(
  //     this.constant.ADD_FULLNAME,
  //     param,
  //     'POST',
  //     '',
  //     false,
  //     false,
  //     false
  //   ).subscribe((results: any) => {
  //     console.log('Added Fullname -> ', results);
  //     localStorage.removeItem("emailFromLogin");
  //   }, err => {
  //     console.log('error in Ading Fullname - ', err);
  //   })
  // }

  async checkVerification() {
    this.loading = true;
    let param = {
      email: this.myForm.value.email,
      verification: this.verificationCode
    }
    console.log(param, "parameter");
    this.common.callApi(
      this.constant.VERIFY_CODE_V2,
      param,
      'POST',
      '',
      false,
      false,
      false
    ).subscribe((results: any) => {
      console.log(results);
      this.wrongCode = false;
      this.loading = false;
      this.verificationSuccess = true;
    }, err => {
      console.log(err);
      if (err?.error?.error?.name == "CodeMismatchException") {
        this.loading = false;
        this.verificationSuccess = false;
        this.wrongCode = true;
        this.config = {
          allowNumbersOnly: true,
          length: 6,
          isPasswordInput: false,
          disableAutoFocus: false,
          inputStyles: {
            border: "1px solid red"
          }
        }
      } else if (err?.error?.error?.name == "ExpiredCodeException") {
        this.loading = false;
        this.verificationSuccess = false;
        this.wrongCode = true;
        this.config = {
          allowNumbersOnly: true,
          length: 6,
          isPasswordInput: false,
          disableAutoFocus: false,
          inputStyles: {
            border: "1px solid red"
          }
        }
        this.util.presentToast("Your code has been expired.")
      }
    })

    // await this.authServices.verifyCode(this.myForm.value.email, this.verificationCode).then(async (codeREs: any) => {
    //   console.log(codeREs, "--------");
    //   if (codeREs != 'ExpiredCodeException') {
    //     if (codeREs != "CodeMismatchException") {
    //       this.wrongCode = false;
    //       this.loading = false;
    //       this.verificationSuccess = true;
    //     } else {
    //       this.loading = false;
    //       this.verificationSuccess = false;
    //       this.wrongCode = true;
    //       this.config = {
    //         allowNumbersOnly: true,
    //         length: 6,
    //         isPasswordInput: false,
    //         disableAutoFocus: false,
    //         inputStyles: {
    //           border: "1px solid red"
    //         }
    //       }
    //     }
    //   } else {
    //     this.loading = false;
    //     this.verificationSuccess = false;
    //     this.wrongCode = true;
    //     this.config = {
    //       allowNumbersOnly: true,
    //       length: 6,
    //       isPasswordInput: false,
    //       disableAutoFocus: false,
    //       inputStyles: {
    //         border: "1px solid red"
    //       }
    //     }
    //     this.util.presentToast("Your code has been expired.")
    //   }
    // });
  }

  async resendOtp() {
    let param = {
      email: this.myForm.value.email
    }
    console.log(param, "parameter");
    this.common.callApi(
      this.constant.VERIFY_CODE_RESEND_V2,
      param,
      'POST',
      '',
      false,
      false,
      false
    ).subscribe((results: any) => {
      console.log(results);
      if (results) {
        this.util.presentToast("A new verification code has been sent to your email.")
      }
    }, err => {
      console.log('error in Resend Code - ', err);
    })

    // resendCode
    // VERIFY_CODE_RESEND_V2
    // await this.authServices.resendCode(this.myForm.value.email).then(async (codeREs: any) => {
    //   console.log(codeREs, "--------");
    //   if (codeREs)
    //     this.util.presentToast("A new verification code has been sent to your email.")
    // })
  }

}
