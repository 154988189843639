import { ActionTypes } from "../constants/action-types";

export const setDescriptionData = (val) => {
  return {
    type: ActionTypes.SET_DESCRIPTION_DATA,
    payload: val,
  };
};

// export const setIsChangeRoom = (val) => {
//   return {
//     type: ActionTypes.SET_IS_ROOM_CHANGE,
//     payload: val,
//   };
// };
