import { ActionTypes } from "../constants/action-types";
const initialState = {
};


export function alertsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.SET_ALERTS_LOCATION:
            return { ...state, alertsActiveLocations: payload };
        case ActionTypes.SET_SCHEDULED_LOCATION:
            return { ...state, alertsScheduledLocations: payload };
        case ActionTypes.SET_ALERTS_ACTIVE_LOCATIONS_LASTUPDATE:
            return { ...state, alertsActiveLocationsLastupdate: payload };
        default:
            return state;
    }
}