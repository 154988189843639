import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrMaskerModule } from 'br-mask';
import { AuthGuard } from './auth.guard';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';

// Redux
import { storeLogger } from 'ngrx-store-logger';
import { StoreModule, ActionReducer } from '@ngrx/store';
import { rootReducer } from '../store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storageSyncMetaReducer } from 'ngrx-store-persist';
import { NgOtpInputModule } from 'ng-otp-input';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { AlphabeticalScrollBarModule } from 'alphabetical-scroll-bar';



export function logger(reducer: ActionReducer<any>): any {
  // default, no options
  return storeLogger()(reducer);
}

export function playerFactory() {
  return player;
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        LottieModule.forRoot({ player: playerFactory }),
        BrowserModule, BrMaskerModule, HttpClientModule, IonicModule.forRoot({}),
        AppRoutingModule,
        TagInputModule,
        BrowserAnimationsModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: "#78C000",
            innerStrokeColor: "#C7E596",
            animationDuration: 300,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        DragDropModule,
        StoreModule.forRoot(rootReducer, { metaReducers: [storageSyncMetaReducer] }),
        StoreDevtoolsModule.instrument({
            maxAge: 15
        }),
        NgOtpInputModule,
        NgSelectModule, FormsModule,
        AlphabeticalScrollBarModule
    ],
    providers: [
        StatusBar,
        UntypedFormBuilder,
        HttpClient,
        AuthGuard,
        ScreenOrientation,
        FingerprintAIO,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
