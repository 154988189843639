import { ActionTypes } from "../constants/action-types";

export const setSelectedTrade = (val) => {
  return {
    type: ActionTypes.SET_SELECTED_TRADE,
    payload: val,
  };
};

export const setCurrentTradeIndex = (val) => {
  return {
    type: ActionTypes.SET_CURRENT_TRADE_INDEX,
    payload: val,
  };
};


export const setTradeData = (val) => {
  return {
    type: ActionTypes.SET_TRADE_DATA,
    payload: val,
  };
};

export const setIsChangeTrade = (val) => {
  return {
    type: ActionTypes.SET_IS_TRADE_CHANGE,
    payload: val,
  };
};
