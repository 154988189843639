import { ActionTypes } from "../constants/action-types";

export const setPunchItem = (val) => {
  return {
    type: ActionTypes.SET_PUNCH_ITEM_DETAIL,
    payload: val,
  };
};

export const setPunchItemLocation = (val) => {
  return {
    type: ActionTypes.SET_PUNCH_ITEM_LOCATION,
    payload: val,
  };
};

export const setSelectedLocationPunchItems = (val) => {
  return {
    type: ActionTypes.SET_SELECTED_LOCATION_PUNCH_ITEMS,
    payload: val,
  };
};

export const setExpandsRoom = (val) => {
  return {
    type: ActionTypes.SET_EXPAND_ROOMS,
    payload: val,
  };
};

export const setExpandsTrades = (val) => {
  return {
    type: ActionTypes.SET_EXPAND_TRADES,
    payload: val,
  };
};

export const setPunchItemsComments = (val) => {
  return {
    type: ActionTypes.SET_PUNCH_ITEMS_COMMENTS,
    payload: val,
  };
};

export const setPunchItemsStore = (val) => {
  return {
    type: ActionTypes.SET_PUNCH_ITEMS_STORE,
    payload: val,
  };
};

export const setExpandsPlans = (val) => {
  return {
    type: ActionTypes.SET_EXPAND_PLANS,
    payload: val,
  };
};