import { ActionTypes } from "../constants/action-types";

export const setActivityFilters = (val) => {
  return {
    type: ActionTypes.SET_ACTIVITY_FILTER,
    payload: val,
  };
};

export const setJobsiteFilters = (val) => {
  return {
    type: ActionTypes.SET_JOBSITE_FILTER,
    payload: val,
  };
};
