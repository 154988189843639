import { ActionTypes } from "../constants/action-types";
const initialState = {
};


export function punchItemDetailReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ActionTypes.SET_PUNCH_ITEM_DETAIL:
            return { ...state, selectedPunchItem: payload };
        case ActionTypes.SET_PUNCH_ITEM_LOCATION:
            return { ...state, selectedPunchItemLocation: payload };
        case ActionTypes.SET_SELECTED_LOCATION_PUNCH_ITEMS:
            return { ...state, selectedLocationPunchItems: payload };
        case ActionTypes.SET_EXPAND_ROOMS:
            return { ...state, expandRooms: payload };
        case ActionTypes.SET_EXPAND_TRADES:
            return { ...state, expandTrades: payload };
        case ActionTypes.SET_PUNCH_ITEMS_COMMENTS:
            return { ...state, punchItemsComments: payload };
        case ActionTypes.SET_PUNCH_ITEMS_STORE:
            return { ...state, punchItemsStore: payload };
        case ActionTypes.SET_EXPAND_PLANS:
            return { ...state, expandPlans: payload };    
        default:
            return state;
    }
}