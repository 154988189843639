import { authReducer } from "./authenticate";
import { locationReducer } from "./location";
import { navigationReducer } from "./navigationVariables";
import { punchItemDetailReducer } from "./punchItemDetail";
import { tradeReducer } from "./trade";
import { userReducer } from "./user";
import { filterReducer } from "./filters";
import { roomReducer } from "./room";
import { descriptionReducer } from "./description";
import { developmentReducer } from "./development";
import { treeDataReducer } from "./treeData";
import { planDetailReducer } from "./planDetail";
import { alertsReducer } from "./alerts";

export const rootReducer = {
    auth: authReducer,
    user: userReducer,
    navigationData : navigationReducer,
    locationData : locationReducer,
    tradeData : tradeReducer,
    punchItemData : punchItemDetailReducer,
    filterData : filterReducer,
    roomData : roomReducer,
    descriptionData : descriptionReducer,
    developmentData: developmentReducer,
    treeData: treeDataReducer,
    planDetail: planDetailReducer,
    alertsData: alertsReducer
}