import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders
} from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { Events } from '../events';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  public hiddenTabs: boolean;
  statee: boolean;
  splitPaneState: boolean;
  isLogin = false;
  baseUrl = 'https://easypunchlist.com:8080';
  imageUrl = 'https://easypunchlist.com:8080/uploads/';
  apiKey = "AIzaSyAnksS1iv67ftV4C-RYnLNbCzpZyWODltE";

  auth: any;
  constructor(
    private http: HttpClient,
    private platform: Platform,
    private events: Events,
    private store: Store<any>
  ) {
    this.splitPaneState = false;
  }

  getCountryCode() {
    return this.http.get("https://ipapi.co/json");
  }

  setSplitPane(state: boolean) {
    this.statee = state;
  }

  getSplitPane() {
    if (this.platform.width() > 850) {
      this.splitPaneState = this.statee;
    } else {
      this.splitPaneState = false;
    }
    return this.splitPaneState;
  }

  getToken() {
    this.store.select<any>('auth').subscribe(state => this.auth = state);
    // return !!localStorage.getItem("token");
    return !!this.auth?.authenticationData?.token?.sessionToken;
  }

  GET_API(url) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("token")
      })
    };
    return this.http.get(this.baseUrl + url, httpOptions);
  }

  GET_API_WITHOUT_TOKEN(url) {
    return this.http.get(this.baseUrl + url, {});
  }

  POST_API(url, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("token")
      })
    };
    return this.http.post(this.baseUrl + url, data, httpOptions)
  }

  POST_API_WITHOUT_TOKEN(url, data) {
    console.log("url", url)
    console.log("data", data)
    return this.http.post(this.baseUrl + url, data, {})
  }

  PUT_API(url, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("token")
      })
    };
    const body = new HttpParams()
      .set("id", data.id)
      .set("data", data.data);
    return this.http.put(this.baseUrl + url, body, httpOptions)
  }

  PUT_API_FOR_INDEX(url, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("token")
      })
    };
    const body = new HttpParams()
      .set("index", data.index)
      .set("data", data.data);
    return this.http.put(this.baseUrl + url, body, httpOptions)
  }

  DELETE_API(url) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("token")
      })
    };
    return this.http.delete(this.baseUrl + url, httpOptions)
  }


  DELETE_API_WITHOUT_TOKEN(url) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };
    return this.http.delete(this.baseUrl + url, httpOptions)
  }

  latLongToAddress(url) {
    return this.http.get(url, {});
  }

  login(email, password) {
    let body = {
      email: email,
      password: password
    };
    console.log('body', body);
    console.log('body');
    return this.http.post(this.baseUrl + '/public/login', body, {});
  }

  createUser(data) {
    let body = {
      fullname: data.fullname,
      email: data.email,
      password: data.password,
      user_type_id: 1,
      status: 0
    }

    console.log(body)

    return this.http.post(this.baseUrl + '/public/register', body, {});
  }

  createTestDriveUser(data) {
    let body = {
      email: data.email,
      password: data.password,
      fullname: data.fullname,
      user_type_id: 1
    }

    console.log(body)
    return this.http.post(this.baseUrl + '/public/userTestDrive', body, {});
  }

  uploadImage(blobData, name, ext) {
    const formData = new FormData();
    formData.append('file', blobData, `myimage.${ext}`);
    formData.append('name', name);

    return this.http.post(this.baseUrl + "/upload_file", formData);
  }

  uploadImageFile(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', file.name);

    return this.http.post(this.baseUrl + "/upload_file", formData);
  }

  numberOrLanelineCheck(data) {
    let body = {
      number: data.number,
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };
    return this.http.post('https://d51ekulg32.execute-api.us-east-2.amazonaws.com/development/twilio/lookup', body, {});
  }

}
